import { Injectable } from "@angular/core";
import { Actions, Effect } from "@ngrx/effects";
import { NgrxEffect, HttpMethod } from "ngrx-helpers";
import { HttpClient } from "@angular/common/http";
@Injectable()
export class AppEffects extends NgrxEffect {
    constructor(private actions$: Actions, http: HttpClient) {
        super(actions$, http);
    }
}

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Observable, Observer } from "rxjs";

@Component({
    selector: "lib-meta-tabs-v2",
    templateUrl: "./meta-tabs-v2.component.html",
    styles: [],
})
export class MetaTabsV2Component implements OnInit {
    @Input() tabs: any = [];
    @Input() selectedIndex = 0;
    @Input() level = 0;
    @Input() selection = { [this.level]: this.selectedIndex };
    constructor() {}
    ngOnInit(): void {
        this.selection = Object.assign(this.selection, {
            [this.level]: this.selectedIndex,
        });
    }
    onSelect(index) {
        this.selection[this.level] = index;
    }
}

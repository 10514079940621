import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";

import { FuseSearchBarModule, FuseShortcutsModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";

import { ToolbarComponent } from "app/layout/components/toolbar/toolbar.component";
import { SharedModule } from "../../../../../projects/shared/src/public_api";
import { MatDialogModule } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { MatListModule } from "@angular/material/list";
import { SwitchRolesComponent } from "./switch-roles/switch-roles.component";

@NgModule({
    declarations: [ToolbarComponent, SwitchRolesComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        SharedModule,
        MatDialogModule,
        TranslateModule,
        MatListModule,
    ],
    exports: [ToolbarComponent],
    entryComponents: [SwitchRolesComponent],
})
export class ToolbarModule {}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../services/common.service';
import { StorageService, UtilService } from '../../../public_api';

@Component({
  selector: 'lib-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordFrom: FormGroup;
  minLength: number = 6;
  maxLength: number = 20;
  constructor(
    private matSnackBar : MatSnackBar,
    private commonService: CommonService,
    private storageService: StorageService,
    private matDialogRef: MatDialogRef<any>,
    private utilService: UtilService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    
    this.utilService.loadTanslations('reset-pwd')
    this.resetPasswordFrom = new FormGroup(
      {
        password: new FormControl('', [Validators.required, Validators.minLength(this.minLength), Validators.maxLength(this.maxLength)]),
        confirmPassword: new FormControl('', [Validators.required, Validators.minLength(this.minLength), Validators.maxLength(this.maxLength)])
      }
    )
  }

  validatePassword(password, confirmPassword){
    if(!this.resetPasswordFrom.valid){
      if(password.trim() == ""){
        this.matSnackBar.open('Please enter password!', '', {duration: 3000});
      }else if(confirmPassword.trim() == ""){
        this.matSnackBar.open('Please enter confirm password!', '', {duration: 3000});
      }else if(password.trim().length < this.minLength ){
        this.matSnackBar.open('Min 6 chars should be entered!', '', {duration: 3000});
      }else if(password.trim().length > this.maxLength){
        this.matSnackBar.open('Max 20 chars should be entered!', '', {duration: 3000});
      } else{
        this.matSnackBar.open('Please enter password!', '', {duration: 3000});
      }  
      return false;
    }
  
    console.log(password, confirmPassword)
    if(password.trim() !== confirmPassword.trim()){
      let msg = this.utilService.getPopupMsg(this.translateService.translations, 'RESET_PWD_ERROR_PASSWORD_MISMATCH')       
      this.matSnackBar.open(msg, '', {duration: 3000});
      return false;
    }
    return true;
  }
  close(){
    this.matDialogRef.close();
  }

  update(){
    console.log(this.resetPasswordFrom.value)
    let {password, confirmPassword} = this.resetPasswordFrom.value;   
    let validatePassword = this.validatePassword(password, confirmPassword);
    if(validatePassword){
      console.log('send request');
      let username = this.storageService.username;
      this.commonService.resetPassword({username, password}).subscribe(
        (res: any) =>{
          if(res.response.success){
            let msg = this.utilService.getPopupMsg(this.translateService.translations, 'RESET_PWD_SUCCESS_UPDATE') 
            this.matSnackBar.open(msg, '', {duration: 3000});
            this.close();
          }else{
            this.matSnackBar.open('Password Changed failure!', '', {duration: 3000})
          }
        }
      )

    }
  }

}

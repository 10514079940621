import { Component, Input, OnInit } from "@angular/core";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
// import { SnackbarService } from "projects/onboarding/src/lib/corporate/services/shared";
// import { CaseManagementServicesService } from "projects/onboarding/src/lib/case-management-services.service";
import { CaseManagementService } from "../case-management.service";


@Component({
    selector: "lib-customer-additional-info",
    templateUrl: "./customer-additional-info.component.html",
    styleUrls: ["./customer-additional-info.component.css"],
})
export class CustomerAdditionalInfoComponent implements OnInit {
    data = [
        {
        },
    ];
    selData = {};
    locationDetails = [];
    identificationNumbersData = [];
    keywordData = [];
    linkedIndividualData = [];
    linkedCompaniesData = [];
    sourcesLinkData = [];
    @Input() receivedRecord: any;
    keyDataSelected = true;
    connectionsSelected = false;
    sourcesSelected = false;
    result = "";
    dataLoaded = false;
    constructor(
        private http: HttpClient,
        private caseManagementService: CaseManagementService,
        private spinner: NgxSpinnerService,
        // private snackbar: SnackbarService
    ) {}

    ngOnInit(): void {
        this.spinner.show();
        this.caseManagementService
            .getDetailsForRecord(this.receivedRecord.refrenceId)
            .subscribe(
                (response) => {
                    this.spinner.show();
                    console.log(response);
                    console.log(response["data"]);
                    this.result = response["data"][0];

                    this.locationDetails = response["data"][0]?.locationDetails;
                    this.identificationNumbersData =
                        response["data"][0]?.identificationNumbers;
                    this.keywordData = response["data"][0]?.keywords;
                    this.linkedIndividualData =
                        response["data"][0]?.linkedIndividuals;
                    this.linkedCompaniesData =
                        response["data"][0]?.linkedCompanies;
                    this.sourcesLinkData = response["data"][0]?.sourceslinks;
                    console.log(this.result);

                    setTimeout(() => {
                        this.dataLoaded = true;
                        this.spinner.hide();
                    }, 500);
                },
                (err) => {
                    this.spinner.hide();
                    // this.snackbar.message({ text: "Server down" });
                }
            );
    }

    displayedColumns: string[] = [
        "name",
        "matchScore",
        "category",
        "statusId",
        "riskId",
        "cor",
    ];
    displayedLocationColumns: string[] = [
        "street",
        "city",
        "postCode",
        "region",
        "country",
    ];
    identificationNumbersDataColumns: string[] = ["number", "type", "country"];
    keywordDataColumns: string[] = ["name", "type", "description", "country"];
    linkedIndividualColumns: string[] = [
        "name",
        "connection",
        "type",
        "category",
    ];
    linkedCompaniesColumns: string[] = [
        "name",
        "connection",
        "type",
        "category",
    ];
    sourcesLinkColumns: string[] = ["date", "link"];
    dataSelected = this.data;
    keyDataDisbled = true;
    furtherInfoSelected = false;
    aliasesSelected = false;
    keyWordsSelected = false;
    connectionsDaaDisabled = false;
    sourcesDataDisabled = false;
    statusriskIds = {
        "5jb7sxfbrelm1houqw8y1eis9": "Positive",
        "5jb7sxfbrelm1houqw8y1eise": "Possible",
        "5jb7sxfbrelm1houqw8y1eisk": "False",
        "5jb7sxfbrelm1houqw8y1eisn": "Unspecified",
        "5jb7sxfbrelm1houqw8y1eis5": "High",
        "5jb7sxfbrelm1houqw8y1eis6": "Medium",
        "5jb7sxfbrelm1houqw8y1eis7": "Low",
        "5jb7sxfbrelm1houqw8y1eis8": "Unknown",
    };
    selectedTab(event: any) {
        if (event.tab.textLabel === "Key Data") {
            this.keyDataSelected = true;
            this.connectionsSelected = false;
            this.sourcesSelected = false;
            this.furtherInfoSelected = false;
            this.aliasesSelected = false;
            this.keyWordsSelected = false;
        }
        if (event.tab.textLabel === "Connections/Relationships") {
            this.keyDataSelected = false;
            this.connectionsSelected = true;
            this.sourcesSelected = false;
            this.furtherInfoSelected = false;
            this.aliasesSelected = false;
            this.keyWordsSelected = false;
        }
        if (event.tab.textLabel === "Sources") {
            this.keyDataSelected = false;
            this.connectionsSelected = false;
            this.sourcesSelected = true;
            this.furtherInfoSelected = false;
            this.aliasesSelected = false;
            this.keyWordsSelected = false;
        }
        if (event.tab.textLabel === "Further Information") {
            this.keyDataSelected = false;
            this.connectionsSelected = false;
            this.sourcesSelected = false;
            this.furtherInfoSelected = true;
            this.aliasesSelected = false;
            this.keyWordsSelected = false;
        }
        if (event.tab.textLabel === "Aliases") {
            this.keyDataSelected = false;
            this.connectionsSelected = false;
            this.sourcesSelected = false;
            this.furtherInfoSelected = false;
            this.aliasesSelected = true;
            this.keyWordsSelected = false;
        }
        if (event.tab.textLabel === "Key Words") {
            this.keyDataSelected = false;
            this.connectionsSelected = false;
            this.sourcesSelected = false;
            this.furtherInfoSelected = false;
            this.aliasesSelected = false;
            this.keyWordsSelected = true;
        }
    }
    getScore(score) {
        return Math.ceil(parseFloat(score));
    }
    getStatusName(statusId) {
        if (statusId === "null") {
            return "Unresolved";
        } else {
            return this.statusriskIds[statusId];
        }
    }
    getRisk(riskId) {
        if (riskId === "null") {
            return "";
        } else {
            return this.statusriskIds[riskId];
        }
    }
    convertDate(dateVal) {
        const date = new Date(dateVal);
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }
    getCategoryCode(ele) {
        let val = "";
        let a = ele.split(" ");
        if (a.length === 1) {
            const isUpper = /^[A-Z]+$/.test(ele);
            if (isUpper) {
                return ele.toUpperCase();
            } else {
                return ele.charAt(0).toUpperCase();
            }
        } else {
            for (let i = 0; i < a.length; i++) {
                val = val + a[i][0];
            }
            return val.toUpperCase();
        }
    }
    getCategoryName(ele) {
        let a = ele.split(" ");
        if (a.length === 1 && ele === "PEP") {
            return "Politically Exposed Person";
        } else {
            return ele;
        }
    }
    addData = false;
    previous = true;
    additionalData(ele) {
        let obj = {
            name: ele.name,
            category: ele.category,
            refrenceId: ele.referenceId,
            cor: "",
            matchScore: "",
            statusId: "",
            riskId: "",
        };
        this.selData = obj;
        this.addData = true;
        this.previous = false;
    }
    convertSourceAddedDate(dateVal) {
        if (dateVal === "" || dateVal === "null" || !dateVal.includes("DATE")) {
            return "";
        } else {
            const date = dateVal.split(":");
            return this.convertDate(date);
        }
    }
}

export class MetaLabelConfig {
    label: string;
    template: string;
    localeCode : string;
    localeData : [];
    constructor(options: { 
        label?: string; 
        template?: string;
        localeCode ?: string;
        localeData ?: []; 
    }) {
        this.label = options.label;
        this.template = options.template || null;
        this.localeCode = options.localeCode || null;
        this.localeData = options.localeData || [];
    }
}
export class MetaLabel extends MetaLabelConfig {
    type = "label";
    constructor(options: {} = {}) {
        super(options);
    }
}

import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
    MetaFieldsConfig,
    MetaFieldsControlService,
    MetaSelect,
    MetaMultiselect,
    MetaDatepicker,
    MetaTextarea,
} from "../../components/meta-fields";
import { MetaTextbox } from "../../components/meta-fields/meta-textbox";
import { of, Observable } from "rxjs";

@Component({
    selector: "lib-meta-fields-demo",
    templateUrl: "./meta-fields-demo.component.html",
    styles: [],
})
export class MetaFieldsDemoComponent implements OnInit {
    constructor(private _metaFieldsControlService: MetaFieldsControlService) {}
    fields$: Observable<MetaFieldsConfig<any>[]>;
    form: FormGroup;
    payLoad = "";
    ngOnInit(): void {
        const fields: MetaFieldsConfig<string>[] = [
            new MetaTextbox({
                id: 1,
                label: "First name",
                key: "firstName",
                value: "Bombasto",
                isMandatory: true,
            }),
            new MetaTextarea({
                id: 2,
                label: "Last name",
                key: "lastName",
                value: "Bombasto",
                isMandatory: true,
            }),
            new MetaDatepicker({
                id: 3,
                label: "DOB",
                key: "dob",
                value: "",
                isMandatory: true,
            }),
            new MetaSelect({
                id: 4,
                label: "Gender",
                key: "gender",
                value: "Male",
                isMandatory: true,
                options: [
                    { id: 1, value: "Male" },
                    { id: 2, value: "Female" },
                ],
            }),
            new MetaMultiselect({
                id: 5,
                label: "Hobbies",
                key: "hobbies",
                value: "Cricket,Football".split(","),
                isMandatory: true,
                options: [
                    { id: 1, value: "Cricket" },
                    { id: 2, value: "Football" },
                    { id: 3, value: "Chess" },
                ],
            }),
        ];
        this.fields$ = of(
            fields.sort((a, b) => a.displayOrder - b.displayOrder)
        );
        this.form = this._metaFieldsControlService.toFormGroup(fields);
    }

    onSubmit() {
        this.payLoad = JSON.stringify(this.form.getRawValue());
    }
}

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "lib-meta-upload",
    templateUrl: "./meta-upload.component.html",
    styleUrls: ["./meta-upload.component.scss"],
})
export class MetaUploadComponent implements OnInit {
    @Input() fileType = null;
    @Input() disabled = null;
    @Output() fileEvent = new EventEmitter<any>();
    constructor() {}

    ngOnInit(): void {}

    onFileInput(files: FileList): void {
        this.fileEvent.emit(files.item(0).name);
    }
   
}

import {
    Component,
    OnInit,
    AfterViewInit,
    ElementRef,
    Inject,
} from "@angular/core";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatCheckbox } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA,MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { map, switchMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { CaseManagementService } from "../case-management.service";
import { MatDialog } from "@angular/material/dialog";
import { TriggerCommentComponent } from "../trigger-comment/trigger-comment.component";
import { MatSort } from "@angular/material/sort";
import { StorageService } from "dil-shared";
import { Router } from "@angular/router";
import { TriggerEventService } from "projects/trigger-event/src/public_api";
import { NgxSpinnerService } from "ngx-spinner";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UtilService } from "projects/shared/src/public_api";
import { TranslateService } from "@ngx-translate/core";


@Component({
    selector: "case-management",
    templateUrl: "./case-management.component.html",
    styleUrls: ["./case-management.component.css"],
})
export class CaseManagementComponent implements AfterViewInit {
    data: any[] = [];

    sortedData = [];
    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        console.log("hello");
    }
    pagedDataSource: any[] = [];
    pageSize = 5;
    checkedEl = [];
    checkedElForResolve = [];
    showResolveSection = false;
    showDetailedSection = false;
    showTableData = true;
    filteredName = "";
    filteredStatus = "";
    filteredStrength = "";
    caseSystemId: string;
    isOngoingNameScreening: any;
    isCorporate: any;
    isTriggered = false;
    isViewOnly=false;
    constructor(
        private dialogRef: MatDialogRef<CaseManagementComponent>,
        @Inject(MAT_DIALOG_DATA) public receivedData,
        private http: HttpClient,
        private _dialog: MatDialog,
        private caseManagementService: CaseManagementService,
        private storageService: StorageService,
        private router: Router,
        private spinner:NgxSpinnerService,
        private triggerEventService: TriggerEventService,
        private snackBar: MatSnackBar,
        private utilService: UtilService,
        private translateService: TranslateService,
    ) {
        this.sortedData = this.data;
    }
    ngOnInit(): void {
        // debugger;
        console.log(this.receivedData.fromOngoingNameScreening);
        console.log(this.receivedData.caseSystemIdsList);
        this.router.url == '/ongoing-name-screening/corporate-ongoing-name-screening'? this.isCorporate = true : this.isCorporate = false;
        this.isOngoingNameScreening = this.receivedData.fromOngoingNameScreening;
        this.dialogRef.disableClose = true;
        this.isViewOnly = this.receivedData.onlyViewPermission;
        this.caseSystemId = this.receivedData.res[0].caseSystemId;
        for (let el in this.receivedData.res) {
            this.data.push(this.receivedData.res[el]);
        }
        this.data = this.receivedData.res;
        this.data.forEach((ele) => {
            ele["completed"] = false;
        });
        this.utilService.loadTanslations("name-screening");
    }
    statusriskIds = {
        "5jb7sxfbrelm1houqw8y1eis9": "Positive",
        "5jb7sxfbrelm1houqw8y1eise": "Possible",
        "5jb7sxfbrelm1houqw8y1eisk": "False",
        "5jb7sxfbrelm1houqw8y1eisn": "Unspecified",
        "5jb7sxfbrelm1houqw8y1eis5": "High",
        "5jb7sxfbrelm1houqw8y1eis6": "Medium",
        "5jb7sxfbrelm1houqw8y1eis7": "Low",
        "5jb7sxfbrelm1houqw8y1eis8": "Unknown",
    };
    @ViewChild(MatPaginator) paginator: MatPaginator;
    dataSource = new MatTableDataSource<any>(this.data);
    displayedColumns: string[] = [
        "position",
        "name",
        "statusId",
        "matchScore",
        "riskId",
        "type",
        "dob",
        "nationality",
        "cor",
        "category",
        "matchedDate",
        "updatedDate",
        "alertDate",
        "resolvedDate",
        "resolvedBy",
    ];
    selData: any = {};
    allcomplete: boolean = false;
    setAll(completed: boolean) {
        //stores only the result ids
        let sel = [];
        //stores result ids along with casesystem id
        let data = [];
        this.allcomplete = completed;
        if (this.data == null) {
            return;
        }
        this.data.forEach((ele) => {
            ele.completed = completed;
            sel.push(ele.resultId);
            let alreadyExists = false;
            for (let eleObj of data) {
                if (eleObj.caseSystemId === ele.caseSystemId) {
                    eleObj["resultId"] = [...eleObj["resultId"], ele.resultId];
                    alreadyExists = true;
                    break;
                }
            }
            if (!alreadyExists) {
                let obj = {};
                obj["resultId"] = [ele.resultId];
                obj["caseSystemId"] = ele.caseSystemId;
                data.push(obj);
            }
            // let obj={};
            // obj["resultId"]=[ele.resultId];
            // obj["caseSystemId"]=ele.caseSystemId;
            // data.push(obj);
        });
        if (completed) {
            this.checkedEl = sel;
            this.checkedElForResolve = data;
        } else {
            this.checkedEl = [];
            this.checkedElForResolve = [];
        }
        console.log(this.checkedEl);
        if (this.checkedEl.length === 0) {
            this.showResolveSection = false;
        }
        console.log(this.checkedEl);
        console.log(this.checkedElForResolve);
    }
    updateData(requestBody) {
        let listDataToResolve = [];
        for (let ele of this.checkedElForResolve) {
            let obj = {};
            obj["caseSystemId"] = ele.caseSystemId;
            let requestDTO = {};
            requestDTO["resultIds"] = ele.resultId;
            requestDTO["reasonId"] = requestBody.reasonId;
            requestDTO["resolutionRemark"] = requestBody.resolutionRemark;
            requestDTO["statusId"] = requestBody.statusId;
            requestDTO["riskId"] = requestBody.riskId;
            obj["requestDTO"] = requestDTO;
            listDataToResolve.push(obj);
        }

        this.caseManagementService.getBulkResolve(listDataToResolve).subscribe(
            (data) => {
                this.caseManagementService
                    .getFinalResultsForMultipleCaseSystemIds(
                        this.receivedData.caseSystemIdsList
                    )
                    .pipe(map((response: any) => response.data))
                    .subscribe(
                        (data: any[]) => {
                            console.log(data);
                            let finalRes = [];
                            for (let i = 0; i < data[0].length; i++) {
                                for (let ele of data[0][i].results) {
                                    finalRes.push(ele);
                                }
                            }
                            this.checkedEl = [];
                            this.checkedElForResolve = [];
                            this.receivedData.res = finalRes;
                            console.log(data);
                            for (let el in this.receivedData.res) {
                                this.data.push(this.receivedData.res[el]);
                            }
                            this.data = this.receivedData.res;
                            this.data.forEach((ele) => {
                                ele["completed"] = false;
                            });
                            this.dataSource = new MatTableDataSource<any>(
                                this.data
                            );
                            this.dataSource.paginator = this.paginator;
                        },
                        (err) => {
                            // this.snackbar.message({ text: "Server down" });
                        }
                    );
            },
            (err) => {
                // this.snackbar.message({ text: "Server down" });
            }
        );

        //   this.http.put(`${environment.locale_local_url}/profile/customer/${this.caseSystemId}/resolution`,val
        // ).subscribe((data)=>{

        // })
    }
    checkedOne(event: any, element) {
        if (event.checked) {
            this.checkedEl.push(element.resultId);

            let alreadyExists = false;
            for (let ele of this.checkedElForResolve) {
                if (ele.caseSystemId === element.caseSystemId) {
                    ele["resultId"] = [...ele["resultId"], element.resultId];
                    alreadyExists = true;
                    break;
                }
            }
            if (!alreadyExists) {
                let obj = {};
                obj["resultId"] = [element.resultId];
                obj["caseSystemId"] = element.caseSystemId;
                this.checkedElForResolve.push(obj);
            }
        } else {
            this.checkedEl = this.checkedEl.filter(
                (ele) => ele != element.resultId
            );
            // this.checkedElForResolve=this.checkedElForResolve.filter(ele=>{
            //   ele.resultId = ele.resultId.filter(arrayEl => arrayEl !== element.resultId);
            //   return true;
            // }
            // )

            for (let ele of this.checkedElForResolve) {
                if (
                    ele.caseSystemId == element.caseSystemId &&
                    ele.resultId.length > 1
                ) {
                    ele.resultId = ele.resultId.filter(
                        (arrEl) => arrEl !== element.resultId
                    );
                }
                // if(ele.resultId.length===1 && ele.caseSystemId==element.caseSystemId){
                //   this.checkedElForResolve=this.checkedElForResolve.filter(checkedResolveEl=>
                //     checkedResolveEl.caseSystemId!=element.caseSystemId
                //   )
                // }
            }
        }
        if (this.checkedEl.length === 0) {
            this.showResolveSection = false;
        }
        console.log(this.checkedEl);
        console.log(this.checkedElForResolve);
    }
    someComplete(): boolean {
        if (this.data == null) {
            return false;
        }
        return (
            this.data.filter((ele) => ele.completed).length > 0 &&
            !this.allcomplete
        );
    }

    updateAllComplete() {
        this.allcomplete =
            this.data != null && this.data.every((ele) => ele.completed);
    }

    trigger() {
        // alert("Hello")
        const dialogConfig = new MatDialogConfig();

        dialogConfig.maxWidth = "70vw";
        dialogConfig.width = "70%";
        dialogConfig.panelClass = "fullscreen-dialog";
       const dialog=this._dialog.open(
           TriggerCommentComponent,
           dialogConfig
       );
        let assignedTo = this.storageService.username;
        // debugger;
        console.log(assignedTo)
        dialog.afterClosed().subscribe((res) => {
            if (res.data) {
                this.spinner.show();
                console.log(this.receivedData.triggerEventRecord)
                let segmentId = this.isCorporate ? 1 : 2;
                let applicationId = this.receivedData.triggerEventRecord.applicationId;
                let bookingLocations = [];
                let bookingLocationsAndContId = [];
                let orgTypeId: any;
                let bookingLocationForCustomer: any[] = [];
                let matchedBookingLoc = [];
                let coi: any;
                this.triggerEventService.searchCustomerDetails(segmentId, "customerId", applicationId)
                    .pipe(
                    switchMap((response) => {
                        bookingLocations = response[0]["data"][0].bookingLocations;
                        bookingLocationsAndContId = response[0]["data"][0].bookingLocationsAndContId;
                        orgTypeId = response[0]["data"][0].orgTypeId;
                        coi = response[0]["data"][0].countryOfIncorporation
                        // debugger;
                        console.log(response[0]["data"][0])
                        if (!this.isCorporate) {
                            this.triggerEventService.getNationalityData().subscribe((response) => {
                            
                                for (let ele of response[0]["data"]) {
                                    if (ele.countryId === coi) {
                                        coi = ele.id
                                        break;
                                    }
                                }
                            
                            }, (err) => {
                                this.spinner.hide();
                                this.snackBar.open(
                                    this.utilService.getPopupMsg(
                                        this.translateService.translations,
                                        "ONGOING_SCREENING_TRIGGER_SERVER_DOWN"
                                    ),
                                    "",
                                    { duration: 3000 }
                                );
                            });
                        }
                        return this.triggerEventService.getBookingLocationData(orgTypeId);
                    })
                )
                    .subscribe((response) => {
                    for (let ele of response[0]["data"][0].bookingLocations) {
                        bookingLocationForCustomer.push(ele.contentId)
                    }
                    for (let ele of bookingLocations) {
                        if (bookingLocationForCustomer.includes(ele)) {
                            matchedBookingLoc.push(ele);
                        }
                    }
                    const body = {
                        id: this.receivedData.triggerEventRecord.customerId,
                        applicationId: applicationId,
                        triggerEventTypes: ["Name Screening Hit"],
                        triggerEventReason: res.data,
                        bookingLocations:matchedBookingLoc,
                        bookingLocationsAndContId: bookingLocationsAndContId,
                        countryOfIncorporation: coi,
                        assignedUser: assignedTo,
                        orgTypeId: orgTypeId,
                    };
                    this.triggerEventService.triggerCustomerDetails(body).subscribe((res) => {
                        this.snackBar.open(
                            this.utilService.getPopupMsg(
                                this.translateService.translations,
                                "ONGOING_SCREENING_TRIGGER_SUCCESS"
                            ),
                            "ok",
                        );
                        this.isTriggered = true;
                        this.spinner.hide();
                        this.closeDialog();
                    }, (err) => {
                        this.snackBar.open(
                            this.utilService.getPopupMsg(
                                this.translateService.translations,
                                "ONGOING_SCREENING_TRIGGER_SERVER_DOWN"
                            ),
                            "ok", 
                        );
                        this.spinner.hide();
                        console.log(err)
                    })
                    }, (error) => {
                        this.snackBar.open(
                            this.utilService.getPopupMsg(
                                this.translateService.translations,
                                "ONGOING_SCREENING_TRIGGER_SERVER_DOWN"
                            ),
                            "ok", 
                        );
                        this.spinner.hide();
                        console.error('Error:', error);
                }); 
            }
        }) 
    }

    closeDialog() {
        const unresolvedCountMap = new Map();
        for (let ele of this.data) {
            if (unresolvedCountMap.has(ele.caseSystemId) && ele.statusId==="null") {
                unresolvedCountMap.set(ele.caseSystemId, unresolvedCountMap.get(ele.caseSystemId) + 1);
            }
            else if(!unresolvedCountMap.has(ele.caseSystemId) && ele.statusId==="null") {
                unresolvedCountMap.set(ele.caseSystemId, 1);
            }
            else if (!unresolvedCountMap.has(ele.caseSystemId) && ele.statusId!=="null") {
                unresolvedCountMap.set(ele.caseSystemId, 0);
            }
        }
        unresolvedCountMap.set("isTriggered", this.isTriggered);
        this.dialogRef.close(unresolvedCountMap);
        console.log(this.checkedEl);
    }
    resolveSection() {
        this.showResolveSection = true;
        console.log(this.checkedEl);
    }
    showResolveSuccessMsg = false;
    resolvedOrNot(val) {
        console.log(val);

        let fildat = this.data.map((e) => {
            if (this.checkedEl.includes(e.resultId)) {
                e.risklevel = val.risk;
                e.status = val.status;
            }
            return e; // Return the updated or unchanged element
        });
        this.allcomplete = false;
        // this.checkedEl=[];
        // this.checkedElForResolve=[];
        this.data.forEach((ele) => {
            ele["completed"] = false;
        });
        this.showResolveSection = false;
        this.showResolveSuccessMsg = true;
        setTimeout(() => {
            this.showResolveSuccessMsg = false;
        }, 3000);
        this.filteredName = "";
        this.filteredStatus = "";
        this.filteredStrength = "";
        this.dataSource = new MatTableDataSource<any>(fildat);
        this.dataSource.paginator = this.paginator;
    }
    selRisk = "";
    riskLevel(val) {
        this.selRisk = val;
    }
    customerDetails(ele) {
        this.showTableData = false;
        this.showDetailedSection = true;
        this.showResolveSection = false;
        this.selData = ele;
    }
    backToData() {
        this.showTableData = true;
        this.showDetailedSection = false;
    }
    applyFilter() {
        let nameFil = this.data.filter((ele) =>
            ele["name"].toLowerCase().includes(this.filteredName.toLowerCase())
        );

        let statusFil = nameFil.filter((ele) => {
            let status;
            if (ele["statusId"] === "null") {
                status = "Unresolved";
            } else {
                status = this.statusriskIds[ele["statusId"]];
            }
            return status
                .toLowerCase()
                .includes(this.filteredStatus.toLowerCase());
        });
        let strengthFil = statusFil.filter(
            (ele) => {
                if (this.filteredStrength !== ""){
                    return Math.ceil(parseFloat(ele["matchScore"])) >= parseInt(this.filteredStrength) 
                }
                else {
                    return ele;
                }
            }
                      
        );
        this.dataSource = new MatTableDataSource<any>(strengthFil);
        this.dataSource.paginator = this.paginator;
    }
    sortStrength() {
        let a = this.data.sort(
            (a, b) => parseInt(a.strength) - parseInt(b.strength)
        );
        this.dataSource = new MatTableDataSource<any>(a);
        this.dataSource.paginator = this.paginator;
    }
    getCategoryCode(ele) {
        let val = "";
        let a = ele.split(" ");
        if (a.length === 1) {
            const isUpper = /^[A-Z]+$/.test(ele);
            if (isUpper) {
                return ele;
            } else {
                return ele.charAt(0);
            }
        } else {
            for (let i = 0; i < a.length; i++) {
                val = val + a[i][0];
            }
            return val;
        }
    }
    getCategoryName(ele) {
        let a = ele.split(" ");
        if (a.length === 1 && ele === "PEP") {
            return "Politically Exposed Person";
        } else {
            return ele;
        }
    }
    getStatusName(statusId) {
        if (statusId === "null") {
            return "Unresolved";
        } else {
            return this.statusriskIds[statusId];
        }
    }
    getDate(date) {
        if (date === null) {
            return "";
        } else {
            const conDate = new Date(date);

            const year = conDate.getFullYear();
            // Month is zero-based, so adding 1 to get the correct month
            const month = conDate.getMonth() + 1;
            const day = conDate.getDate();
            return `${year}-${month.toString().padStart(2, "0")}-${day
                .toString()
                .padStart(2, "0")}`;
        }
    }
    getResolveDate(date) {
        if (date === "null" || date === null) {
            return "";
        } else {
            const conDate = new Date(date);

            const year = conDate.getFullYear();
            // Month is zero-based, so adding 1 to get the correct month
            const month = conDate.getMonth() + 1;
            const day = conDate.getDate();
            return `${year}-${month.toString().padStart(2, "0")}-${day
                .toString()
                .padStart(2, "0")}`;
        }
    }
    getRisk(riskId) {
        if (riskId === "null") {
            return "";
        } else {
            return this.statusriskIds[riskId];
        }
    }
    getScore(score) {
        return Math.ceil(parseFloat(score));
    }

    sortData(sort: MatSort) {
        const data = this.data.slice(); 
        if (!sort.active || sort.direction === "") { 
            this.sortedData = data; 
            return; 
        }
        this.sortedData = data.sort((a, b) => {
            const isAsc = sort.direction === "asc"; 
            switch (sort.active) { 
                case "updatedDate": 
                return compare(a.updatedDate, b.updatedDate, isAsc); 
            default: 
                return 0; 
            } 
        })
        this.dataSource = new MatTableDataSource<any>(this.sortedData);
        this.dataSource.paginator = this.paginator;
    }
}
function compare(a: number | string, b: number | string, isAsc: boolean) { 
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1); 
}

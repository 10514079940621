import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { StorageService } from '../../../public_api';

@Component({
  selector: 'lib-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  constructor(
    private matDialogRef: MatDialogRef<TermsConditionsComponent>,
    private storageService: StorageService
  ) { }

  ngOnInit() {
  }

  agreed(){
    this.matDialogRef.close('yes');
  }

  close(){
    this.matDialogRef.close('no');
  }
}

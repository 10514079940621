import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-meta-tabs-v2-demo',
  template: `
    <p>
      <lib-meta-tabs-v2-wrapper></lib-meta-tabs-v2-wrapper>
    </p>
  `,
  styles: [
  ]
})
export class MetaTabsV2DemoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

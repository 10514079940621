import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MetaUploadComponent } from "./meta-upload/meta-upload.component";
import { MaterialModule } from "../material.module";
import { TranslateModule } from '@ngx-translate/core';

const components = [MetaUploadComponent];
@NgModule({
    declarations: [...components],
    imports: [CommonModule, MaterialModule, TranslateModule],
    exports: [...components],
})
export class MetaUploadModule {}


import { Type } from "@angular/core";
import { AccessRights } from "projects/onboarding/src/lib/corporate/constants/access-permission.constants";

export class MetaTab {
    public id: number;
    public title: string;
    public localeCode: string;
    public localeData: [];
    public tabData: any;
    public active: boolean;
    public component: Type<any>;
    public children: MetaTab[];
    public tabId: number;
    public tabName: string;
    public tabStatus: string;
    public comments: string;
    public tabLocalDataName: any;
    public tabPermissions: AccessRights;
    constructor(
        options: {
            tabId?: number;
            tabName?: string;
            tabStatus?: string;
            comments?: string;
            component?: Type<any>;
            title?: string;
            localeCode?: string;
            localeData?: [];
            tabData?: any;
            children?: MetaTab[];
            tabLocalDataName?: any;
            tabPermissions?: AccessRights;
        } = {}
    ) {
        this.tabId = options.tabId || null;
        this.tabName = options.tabName || null;
        this.tabStatus = options.tabStatus || null;
        this.comments = options.comments || null;
        this.tabData = options.tabData || null;
        this.component = options.component || null;
        this.title = options.title || null;
        this.localeCode = options.localeCode || null;
        this.localeData = options.localeData || null;
        this.children = options.children || null;
        this.tabLocalDataName = options.tabLocalDataName || null;
        this.tabPermissions = options.tabPermissions || null;
    }
}

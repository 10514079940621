import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "lib-meta-accordion",
    templateUrl: "./meta-accordion.component.html",
    styleUrls: ["./meta-accordion.component.scss"],
})
export class MetaAccordionComponent implements OnInit {
    @Input() title = null;
    @Input() count = 0;
    @Input() first = false;
    @Input() disabled = false;
    @Input() id: any;
    constructor() {}

    ngOnInit(): void {}
}

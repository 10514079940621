import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "lib-meta-translate",
    template: `
        <ng-container *ngIf="localeData; else code">
            {{localeData | localeLabel: localeId}}
        </ng-container>
        <ng-template #code>
            {{ localeCode | translate }}
        </ng-template>
    `,
    styles: [],
})
export class MetaTranslateComponent implements OnInit {
    @Input() localeData;
    @Input() localeCode;
    @Input() localeId;
    constructor() {}

    ngOnInit(): void {}
}

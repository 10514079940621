import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MetaAccordionComponent } from "./meta-accordion/meta-accordion.component";
import { MaterialModule } from "../material.module";

const components = [MetaAccordionComponent];
@NgModule({
    declarations: [...components],
    imports: [CommonModule, MaterialModule],
    exports: [...components],
})
export class MetaAccordionModule {}

import { NgModule } from "@angular/core";
import { SharedComponent } from "./shared.component";
import { DilSpinnerComponent } from "./dil-spinner/dil-spinner.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { EnableScrollDirective } from "./directives";
import { HttpClientModule } from "@angular/common/http";

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { TermsConditionsComponent } from "./components/terms-conditions/terms-conditions.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { CommonModule } from "@angular/common";
import { LimitDecimalDirective } from "./directives/limit-decimal.directive";
import { ReplaceXPipe } from "./pipes/replace-x.pipe";
@NgModule({
    declarations: [
        SharedComponent,
        DilSpinnerComponent,
        EnableScrollDirective,
        ResetPasswordComponent,
        TermsConditionsComponent,
        FileUploadComponent,
        LimitDecimalDirective,
        ReplaceXPipe,
    ],
    imports: [
        CommonModule,
        NgxSpinnerModule,
        HttpClientModule,
        MatCardModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
    exports: [
        SharedComponent,
        DilSpinnerComponent,
        EnableScrollDirective,
        ResetPasswordComponent,
        TermsConditionsComponent,
        FileUploadComponent,
        LimitDecimalDirective,
        ReplaceXPipe,
    ],
    entryComponents: [ResetPasswordComponent, TermsConditionsComponent],
})
export class SharedModule {}

import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @Input() type: any = 'IDV';
  @Input() documentInfo;
  @Input() indexVal = 0;;
  fileName: string;
  @Input() documentInfoId
  @Input() docName
  @Output() uploadSignal: EventEmitter<any> = new EventEmitter<any>()
  @ViewChild('fileInput', { static: true }) fileInput;

  constructor(
    private eleRef: ElementRef
  ) { }

  ngOnInit() {
  }
  
  fileUpload(fileInput, docId, name){
    const file: File = fileInput.files[0];
    console.log(file);
    let fileName = file.name;

    if(fileName.lastIndexOf('.') > -1){
      let file_name = fileName.substr(0, fileName.lastIndexOf('.')),
          fileLength = file_name.length,
          file_ext = fileName.substr(fileName.lastIndexOf('.')+1, fileName.length);
      if(file_name.length > 12){
         fileName = `${file_name.substr(0, 6)}...${file_name.substring(fileLength-4, fileLength)}.${file_ext}`;
      }
    }    
    this.fileName = fileName;

    this.uploadSignal.emit({
      fileInput, docId, name, type: this.type, indexVal: this.indexVal
    })
  }

  triggerFileUpload(){
    this.eleRef.nativeElement.querySelector('#fileUpload').click()
  
  }
}

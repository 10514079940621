// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
var domainName = window.location.hostname;
//var domainName = "localhost";
export var environment: any = {
    production: false,
    hmr: false,
    domain: "",
    email: "rajendar.kethadi@valuelabs.com,raja.boddu@valuelabs.com",
    base_url: `https://${domainName}/api/v2`,
    dev_url: `https://${domainName}`,
    endcustomer_url: `https://${domainName}`,
    locale_local_url: `https://${domainName}/api/v2`,
    locale_cat_url: `https://${domainName}/api/v2`,
    country_url: `https://${domainName}/api/v2`,
    dev_url_gateway: "",
    ip_address: `https://${domainName}:8443`,

    sso: {
        clientId: "cdd",
        clientSecret: "DnZfUDM7vnHiCGuY3nhHXWkHJQTC9I1e",
        serverUrl: `https://${domainName}:8443/realms/`,
        issuer: "",
        redirectUri: window.location.origin + "/",
        scope: "openid",
        responseMode: "fragment",
        tokenEndpoint: "/protocol/openid-connect/token",
        userinfoEndpoint: "/protocol/openid-connect/userinfo",
        authorizationEndpoint: "/protocol/openid-connect/auth",
        jwksEndpoint: "/protocol/openid-connect/jwks",
        showDebugInformation: true,
        requireHttps: false,
        responseType: "code",
    },
};

import {
    Component,
    Input,
    ComponentFactoryResolver,
    ViewChild,
    OnInit,
} from "@angular/core";
import { ContentContainerDirective } from "./content-container.directive";
import { SkeletonComponent } from "./skeleton.component";
import { MetaTab } from "./meta-tab.model";
import { EmptyTabComponent } from "./empty-tab.component";

@Component({
    selector: "app-tab-content",
    template: "<ng-template content-container></ng-template>",
})
export class TabContentComponent implements OnInit {
    @Input() tab;
    @Input() tabContentLevel = null;
    @Input() exportFlag = null;
    @Input() exportGetDataForPost;
    @Input() localeDataName;
    @Input() SendEmailLink = null;
    @Input() flagForCancel = null;
    @ViewChild(ContentContainerDirective, { static: true })
    contentContainer: ContentContainerDirective;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

    ngOnInit() {
        const tab: MetaTab = this.tab;
        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(
                tab.component || EmptyTabComponent
            );
        const viewContainerRef = this.contentContainer.viewContainerRef;
        const componentRef = viewContainerRef.createComponent(componentFactory);
        (componentRef.instance as SkeletonComponent).data = tab.tabData;
        (componentRef.instance as SkeletonComponent).tabId = tab.tabId;
        (componentRef.instance as SkeletonComponent).tabName = tab.tabName;
        (componentRef.instance as SkeletonComponent).tabStatus = tab.tabStatus;
        (componentRef.instance as SkeletonComponent).comments = tab.comments;
        (componentRef.instance as SkeletonComponent).tabContentLevel =
            this.tabContentLevel;
        (componentRef.instance as SkeletonComponent).exportFlag =
            this.exportFlag;
        (componentRef.instance as SkeletonComponent).exportGetDataForPost =
            this.exportGetDataForPost;
        (componentRef.instance as SkeletonComponent).localeData =
            tab.localeData;
        (componentRef.instance as SkeletonComponent).localeDataName =
            this.localeDataName;
        (componentRef.instance as SkeletonComponent).SendEmailLink =
            this.SendEmailLink;
        (componentRef.instance as SkeletonComponent).flagForCancel =
            this.flagForCancel;
        (componentRef.instance as SkeletonComponent).tabPermissions =
            tab.tabPermissions;
    }
}

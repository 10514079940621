import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MetaFormsControlService } from "../../../components/meta-forms/services/meta-forms-control.service";

@Component({
    selector: "lib-meta-form-demo",
    templateUrl: "./meta-form-demo.component.html",
    styleUrls: ["./meta-form-demo.component.css"],
})
export class MetaFormDemoComponent implements OnInit {
    @Input() questions: [] = [];
    form: FormGroup;
    payLoad = "";
    localeId = 1;
    constructor(private mfcs: MetaFormsControlService) {}

    ngOnInit(): void {
        this.form = this.mfcs.toFormArray(this.questions, false);
    }

    onSubmit(): void {
        this.payLoad = JSON.stringify(this.form.getRawValue());
    }
}

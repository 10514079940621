import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap, map, pluck } from "rxjs/operators";
import { StorageService } from "dil-shared";
import { FuseTranslationLoaderService } from "@fuse/services/translation-loader.service";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, BehaviorSubject, zip, of, Observable } from "rxjs";
import { environment } from "environments/environment";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { navigation } from "app/navigation/navigation";
import * as _ from "lodash";
import { SSOService } from "app/oauth2/sso/sso.service";
import { AppComponent } from "app/app.component";
import { FuseNavigation } from "@fuse/types";
import { access } from "fs";
class LocaleTransform {
    id: number;
    localeId: number;
    title: string;
    constructor(obj: any) {
        this.id = obj.localeCode;
        this.localeId = obj.id;
        this.title = obj.description;
    }
}
@Injectable({
    providedIn: "root",
})
export class MainService {
    navigation: FuseNavigation[];
    tanentId = this.storageService.tenantId;
    locale_url: string = `${environment.locale_local_url}/onboard/tenant/${this.tanentId}/locale`;
    locale_json: string = "assets/languages.json";
    private tenantIntegrationServicesUrl = `${environment.locale_local_url}/onboard/tenant`;

    constructor(
        private http: HttpClient,
        //private appComponent:AppComponent,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _fuseNavigationService: FuseNavigationService,
        private storageService: StorageService,
        private sso: SSOService
    ) {}
    getLocales() {
        console.log("callledddd");
        let tanentId = this.storageService.tenantId || 1;
        return this.http
            .get(
                `${environment.locale_local_url}/onboard/tenant/${tanentId}/locale`
            )
            .pipe(
                pluck("data"),
                map((val: any) => {
                    // console.log(val)
                    let languageList = val.map(
                        (item) => new LocaleTransform(item.locale)
                    );

                    return languageList;
                })
            );
    }

    getConfiguredStatus(id) {
        console.log(id);
        if (id != undefined) {
            return this.http.get(
                `${environment.locale_local_url}/onboard/tenant/configure/${id}`
            );
        }
    }

    getRoleContents() {
        let geUserRoleUrl = `${environment.base_url}/master/category?codes=USR_RLS`;
        let response1 = this.http.get<any[]>(geUserRoleUrl);
        return forkJoin([response1]);
    }

    checkRiskEngineIntegration(
        tenantId: string
    ): Observable<{ oldRisk: boolean; newRisk: boolean }> {
        return this.http
            .get<any>(
                `${this.tenantIntegrationServicesUrl}/${tenantId}/integrationServices`
            )
            .pipe(
                map((response) => {
                    const data = response.data;
                    const oldRisk = data.find(
                        (item) => item.integrationType === "RE_V1"
                    );
                    const newRisk = data.find(
                        (item) => item.integrationType === "RE_V2"
                    );
                    return { oldRisk, newRisk };
                })
            );
    }

    setNavigation(role) {
        console.log("set navigation method is invoked:::::");
        this.navigation = _.cloneDeep(navigation);
        let isConfigured = this.storageService.getItem("isConfigured");
        let tenantName = this.storageService.getItem("vendor");
        let tenantId = this.storageService.getItem("tenantId");
        // this.sso.getUserDetails().subscribe((userDetails) => {
        //     let evaluateDto = {
        //         userName: userDetails.username,
        //         roleName: userDetails.role,
        //         resourceType: "HomePage",
        //         scope: "view",
        //     };
        // let respObj = data;
        if (isConfigured === "true" || tenantName === "master") {
            const accessRights = localStorage.getItem("accessRights");
            // Fetch risk engine integration status
            this.checkRiskEngineIntegration(tenantId).subscribe(
                ({ oldRisk, newRisk }) => {
                    // Filter the navigation based on role permissions and risk integration status
                    this.navigation[0].children =
                        this.navigation[0].children.filter((item) => {
                            const hasAccess = accessRights.includes(item.name);

                            if (
                                item.name ===
                                "RISK-ENGINE-CALCULATION-CONFIG_VIEW"
                            ) {
                                return hasAccess && newRisk; // Show if the role has access and newRisk is true
                            }
                            if (
                                item.name === "RISK-ENGINE_VIEW" ||
                                item.name === "RISK-RATING-CONFIGURATION_VIEW"
                            ) {
                                return hasAccess && oldRisk; // Show if the role has access and oldRisk is true
                            }
                            return hasAccess;
                        });

                    const segId: number[] =
                        localStorage.getItem("customerSegment") !== "undefined"
                            ? JSON.parse(
                                  localStorage.getItem("customerSegment")
                              )
                            : [1, 2];

                    this.navigation[0].children.map((item) => {
                        item.children = item.children?.filter((child) =>
                            segId.includes(child.segmentType)
                        );
                    });

                    console.log(
                        this.navigation,
                        "navigation updated for role:",
                        role
                    );
                    this._fuseNavigationService.unregister("main");
                    this._fuseNavigationService.register(
                        "main",
                        this.navigation
                    );
                    this._fuseNavigationService.setCurrentNavigation("main");
                }
            );
        }
    }
    loadedLocales = new BehaviorSubject<boolean>(false);
    loadTranslationIntoApp(langs: any = [], role = null) {
        if (!langs.length)
            langs = JSON.parse(this.storageService.getItem("langMap"));
        let languages = langs.map((lang) => {
            let tenant = this.storageService.vendor;
            return this.http.get(
                `${environment.dev_url_gateway}/assets/tenants/${tenant}/navigation/labels/${lang.id}.json`
            );
        });
        return zip(...languages);
    }
}

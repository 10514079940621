import { Injectable } from "@angular/core";
import { MetaTab } from "../../components/meta-tabs/meta-tabs/meta-tab.model";
import { Comp1Component } from "./components/comp1.component";
import { Comp2Component } from "./components/comp2.component";
import { of } from "rxjs";

@Injectable()
export class TabService {
    getTabs() {
        const tabs: MetaTab[] = [
            new MetaTab({
                title: "IDV & Documentation",
                tabData: { parent: "IDV & Documentation" },
                children: [
                    new MetaTab({
                        title: "Customer",
                        tabData: { parent: "Customer" },
                        children: [],
                    }),
                    new MetaTab({
                        component: Comp1Component,
                        title: "Controllers Individual",
                        tabData: { parent: "Controllers Individual" },
                        children: [],
                    }),
                    new MetaTab({
                        component: Comp1Component,
                        title: "BO Individual",
                        tabData: { parent: "BO Individual" },
                        children: [],
                    }),
                    new MetaTab({
                        component: Comp1Component,
                        title: "BO No Individual",
                        tabData: { parent: "BO No Individual" },
                        children: [],
                    }),
                ],
            }),
            new MetaTab({
                component: Comp2Component,
                title: "Documentation Tracking",
                tabData: { parent: "Documentation Tracking" },
                children: [],
            }),
        ];
        return of(tabs);
    }
}

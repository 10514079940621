import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: "lib-resolve-section",
    templateUrl: "./resolve-section.component.html",
    styleUrls: ["./resolve-section.component.css"],
})
export class ResolveSectionComponent implements OnInit {
    reason = "";
    statusriskIds = {
        Positive: "5jb7sxfbrelm1houqw8y1eis9",
        Possible: "5jb7sxfbrelm1houqw8y1eise",
        False: "5jb7sxfbrelm1houqw8y1eisk",
        Unspecified: "5jb7sxfbrelm1houqw8y1eisn",
        High: "5jb7sxfbrelm1houqw8y1eis5",
        Medium: "5jb7sxfbrelm1houqw8y1eis6",
        Low: "5jb7sxfbrelm1houqw8y1eis7",
        Unknown: "5jb7sxfbrelm1houqw8y1eis8",
    };
    reasonIds = {
        "Full Match": "5jb7sxfbrelm1houqw8y1eis1",
        "Partial Match": "5jb7sxfbrelm1houqw8y1eis2",
        "No Match": "5jb7sxfbrelm1houqw8y1eis3",
        Unknown: "5jb7sxfbrelm1houqw8y1eis4",
    };
    riskLevels = ["High", "Medium", "Low", "Unknown"];
    statusLevels = ["Positive", "Possible", "False"];
    constructor(private fb: FormBuilder) {}
    resolveForm: FormGroup;
    ngOnInit(): void {
        this.resolveForm = this.fb.group({
            status: ["", Validators.required],
            riskLevel: ["", Validators.required],
            comment: [""],
        });
    }
    statusChange(event: any) {
        this.resolveForm.get("riskLevel").reset();
        let val = event.value;
        if (val === "Positive") {
            this.reason = "Full Match";
        } else if (val === "Possible") {
            this.reason = "Partial Match";
        } else if (val === "False") {
            this.reason = "No Match";
        } else {
            this.reason = "Unknown";
        }
    }
    @Output() resolveOrNot = new EventEmitter<Object>();
    @Output() riskLevel = new EventEmitter<String>();
    @Output() updateData = new EventEmitter<Object>();
    @Input() selectedRecords;
    @Input() caseSystemId;
    resolveComp: boolean = false;
    // riskLevelSel="";
    resolved() {
        this.resolveComp = true;
        this.resolveOrNot.emit({
            resolveornot: this.resolveComp,
            risk: this.resolveForm.value.riskLevel,
            status: this.resolveForm.value.status,
        });
        let resolvedData = {
            resultIds: this.selectedRecords,
            statusId: this.statusriskIds[this.resolveForm.value.status],
            riskId: this.statusriskIds[this.resolveForm.value.riskLevel],
            reasonId: this.reasonIds[this.reason],
            resolutionRemark: this.resolveForm.value.comment,
        };
        this.updateData.emit(resolvedData);
        console.log(this.caseSystemId);
        this.riskLevel.emit(this.resolveForm.value.riskLevel);
    }
    reset() {
        this.resolveForm.reset();
        this.reason = "";
    }
    disableRisk(level) {
        if (this.reason === "Full Match" && level === "Unknown") {
            return true;
        }
        if (
            (this.reason === "No Match" || this.reason === "Unknown") &&
            (level === "High" || level === "Medium" || level === "Low")
        ) {
            return true;
        }
    }
}

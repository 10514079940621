import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../material.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MetaFieldsControlService } from "./services/meta-fields-control.service";
import { MetaFieldsComponent } from "./meta-fields/meta-fields.component";
import { TranslateModule } from "@ngx-translate/core";
import { MetaSharedModule } from "../meta-shared/meta-shared.module";

const components = [MetaFieldsComponent];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        MetaSharedModule,
    ],
    providers: [MetaFieldsControlService],
    exports: [...components],
})
export class MetaFieldsModule {}

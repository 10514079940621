import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormArray, FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
    selector: "lib-meta-form-composite",
    templateUrl: "./meta-form-composite.component.html",
    styleUrls: ["./meta-form-composite.component.scss"],
})
export class MetaFormCompositeComponent implements OnInit {
    @Input() localeId = 1;
    @Input() items: any = [];
    @Input() form = null;
    @Input() label: any;
    @Input() index: any;
    @Output() saveUpdateData = new EventEmitter<{ updatedData }>();

    constructor() {}
    ngOnInit(): void {
        console.warn("Compoiste Items", this.items);
        console.log(this.items);
        console.log(this.form);
    }
    checkDataUpdation(value) {
        this.saveUpdateData.emit({ updatedData: true });
    }

    localeData(index: number) {
        return this.items?.compositeFieldListItems[
            index
        ]?.compositeDetails.localeData.find(
            (item) => item.localeId == this.localeId
        ).name;
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "lib-meta-form-conditional",
    templateUrl: "./meta-form-conditional.component.html",
    styleUrls: ["./meta-form-conditional.component.css"],
})
export class MetaFormConditionalComponent implements OnInit {
    @Input() localeId = 1;
    @Input() items: any = [];
    @Input() form = null;
    @Input() label: any;
    @Input() index: any;
    @Output() saveConditionalUpdateData = new EventEmitter<{ updatedData }>();
    metaData: any;

    constructor() {}

    ngOnInit(): void {}
    checkDataUpdation() {
        this.saveConditionalUpdateData.emit({ updatedData: this.form });
    }

    fieldMetaData(index: number) {
        return this.items?.conditionalFieldListItems[index]?.conditionalDetails
            ?.additionalConfig[0];
    }
    localeData(index: number) {
        return this.items?.conditionalFieldListItems[
            index
        ]?.conditionalDetails?.localeData.find(
            (item) => item.localeId == this.localeId
        ).name;
    }
}

import { Component } from "@angular/core";
import { Observable, of } from "rxjs";
// import { Mock } from "./_mock";
import { MetaFormsUtils } from "../../components/meta-forms/utils/meta-forms.utils";
import { Mock } from "./_mock copy";
@Component({
    selector: "lib-meta-form-wrapper-demo",
    templateUrl: "./meta-form-wrapper-demo.component.html",
    styleUrls: ["./meta-form-wrapper-demo.component.css"],
})
export class MetaFormWrapperDemoComponent {
    // questions$: Observable<MetaField<any>[]>;
    questions$: Observable<any[]>;

    constructor() {
        try {
            //this.questions$ = of(MetaFormsUtils.convertToMetaForm(Mock));
        } catch (e) {
            //Error handling
        }
    }
}

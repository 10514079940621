import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "applications",
        //title    : 'Applications',
        title: "",
        /*translate: 'APPLICATIONS',*/
        type: "group",
        children: [
            {
                id: "onboard",
                //name: "Tenant-onboard",
                name: "TENANT-ONBOARD_VIEW",
                title: "Onboard Tenant",
                translate: "Onboard Tenant",
                type: "item",
                icon: "email",
                url: "/onboard-tenant",
                role: "dadmin",
                resourceType: "HomePage",
            },
            {
                id: "access-management",
                //name: "Access-management",
                name: "ACCESS-MANAGEMENT_VIEW",
                title: "access-management",
                translate: "Access Management",
                type: "item",
                icon: "email",
                url: "/access-management",
                role: "tadmin",
                resourceType: "HomePage",
            },
            {
                id: "role-management",
                //name: "Role-management",
                name: "ROLE-MANAGEMENT_VIEW",
                title: "role-management",
                translate: "Role Management",
                type: "item",
                icon: "email",
                url: "/role-management",
                role: "tadmin",
                resourceType: "HomePage",
            },
            {
                id: "user-management",
                //name: "User-management",
                name: "USER-MANAGEMENT_VIEW",
                title: "user-management",
                translate: "User Management",
                type: "item",
                icon: "email",
                url: "/user-management",
                role: "tadmin",
                resourceType: "HomePage",
            },
            {
                id: "resource-managment",
                //name: "Resource-management",
                name: "RESOURCE-MANAGEMENT_VIEW",
                title: "resource-managment",
                translate: "Resource Management",
                type: "item",
                icon: "email",
                url: "/resource-managment",
                role: "dadmin",
                resourceType: "HomePage",
            },
            {
                id: "application-preferences",
                //name: "application-preferences",
                name: "APPLICATION-PREFERENCES_VIEW",
                title: "application-preferences",
                translate: "Application Preferences",
                type: "item",
                icon: "email",
                url: "/application-preferences",
                role: "dadmin",
                resourceType: "HomePage",
            },
            {
                id: "configurePlatform",
                //name: "ConfigurePlatform",
                name: "CONGIGUREPLATFORM_VIEW",
                title: "Configure The Platform",
                translate: "Configure The Platform",
                type: "item",
                icon: "email",
                url: "/onboard-tenant/onboard-option",
                role: "cadmin",
                resourceType: "HomePage",
            },
            {
                id: "ManageMasterCategories",
                //name: "ManageMasterCategories",
                name: "MANAGEMASTERCATEGORIES_VIEW",
                title: "Manage Master Categories",
                translate: "NAVIGATION_LABEL_MANAGE_MASTER_CATEGORIES",
                type: "item",
                icon: "category",
                url: "/manage-master-categories",
                customIcon: {
                    black: "assets/icons/navigation/black/Manage Master Categories_32 X 32.png",
                    white: "assets/icons/navigation/white/Manage Master Categories_32 X 32.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
            },
            {
                id: "ManagMasterData",
                //name: "ManageMasterData",
                name: "MANAGEMASTERDATA_VIEW",
                title: "Manage Master Data",
                translate: "NAVIGATION_LABEL_MANAGE_MASTER_DATA",
                type: "item",
                icon: "perm_media",
                url: "/manage-master-data",
                customIcon: {
                    black: "assets/icons/navigation/black/Manage Master Data_32 X 32.png",
                    white: "assets/icons/navigation/white/Manage Master Data_32 X 32.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
            },
            {
                id: "Country",
                //name: "Country-configuration",
                name: "COUNTRY-CONFIGURATION_VIEW",
                title: "Country",
                translate: "NAVIGATION_LABEL_COUNTRY",
                type: "item",
                icon: "language",
                url: "/booking-location",
                customIcon: {
                    black: "assets/icons/navigation/black/Country_32 X 32.png",
                    white: "assets/icons/navigation/white/Country_32 X 32.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
            },
            {
                id: "IdvInfo",
                //name: "IDV-Configuration",
                name: "IDV-CONFIGURATION_VIEW",
                title: "Idv Configuration",
                translate: "NAVIGATION_LABEL_IDV_INFORMATION",
                type: "collapsable",
                icon: "info",
                //url      : '/idv-information',
                customIcon: {
                    black: "assets/icons/navigation/black/IDV Information_32 X 32.png",
                    white: "assets/icons/navigation/white/IDV Information_32 X 32.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-idv-info",
                        //name: "Corporate-IDV-Configuration",
                        name: "CORPORATE-IDV-CONFIGURATION_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "idv-information/corporate-idv-info",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-idv-info",
                        //name: "Retail-IDV-Configuration",
                        name: "RETAIL-IDV-CONFIGURATION_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "idv-information/retail-idv-info",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "idv-doc-config",
                //name: "IDV-Documantion",
                name: "IDV-DOCUMENTATION_VIEW",
                title: "IDV Documentation",
                translate: "NAVIGATION_LABEL_IDV_DOCUMENTATION",
                type: "collapsable",
                icon: "note",
                customIcon: {
                    black: "assets/icons/navigation/black/IDV Documentation_32 X 32.png",
                    white: "assets/icons/navigation/white/IDV Documentation_32 X 32.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-idv-doc",
                        //name: "Corporate-IDV-Documation",
                        name: "CORPORATE-IDV-DOCUMANTION_VIEW",
                        title: "Corporate",
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        segmentType: 1,
                        icon: "keyboard_arrow_right",
                        url: "idv-doc-config/corporate-idv-doc",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-idv-doc",
                        //name: "Retail-IDV-Documantation",
                        name: "RETAIL-IDV-DOCUMANTATION_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "idv-doc-config/retail-idv-doc",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "search-customer",
                //name: "Search-Customer",
                name: "SEARCH-CUSTOMER_VIEW",
                title: "Search Customer",
                translate: "NAVIGATION_LABEL_SEARCH_CUSTOMER",
                type: "collapsable",
                icon: "search",
                //url: "/search-customer",
                customIcon: {
                    black: "assets/icons/navigation/black/Search Customer_2_32 X 32.png",
                    white: "assets/icons/navigation/white/Search Customer_2_32 X 32.png",
                },
                role: "maker",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-search-customer",
                        //name: "Corporate-Search-Customer",
                        name: "CORPORATE-SEARCH-CUSTOMER_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "search-customer/corporate-search-customer",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-search-customer",
                        //name: "Retail-Search-Customer",
                        name: "RETAIL-SEARCH-CUSTOMER_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "search-customer/retail-search-customer",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "pool-config",
                //name: "Pool",
                name: "POOL_VIEW",
                title: "Pool Config",
                translate: "NAVIGATION_LABEL_POOL",
                type: "collapsable",
                icon: "group_add",
                customIcon: {
                    black: "assets/icons/navigation/black/Pool_32 X 32.png",
                    white: "assets/icons/navigation/white/Pool_32 X 32.png",
                },
                role: "maker",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-pool-config",
                        //name: "Corporate-Pool",
                        name: "CORPORATE-POOL_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "pool-config/corporate-pool-config",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-pool-config",
                        //name: "Retail-Pool",
                        name: "RETAIL-POOL_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "pool-config/retail-pool-config",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "queue-config",
                //name: "Queue",
                name: "QUEUE_VIEW",
                title: "Queue Config",
                translate: "NAVIGATION_LABEL_MY_QUEUE",
                type: "collapsable",
                icon: "group_add",
                customIcon: {
                    black: "assets/icons/navigation/black/My Queue_32 X 32.png",
                    white: "assets/icons/navigation/white/My Queue_32 X 32.png",
                },
                role: "maker",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-queue-config",
                        //name: "Corporate-Queue",
                        name: "CORPORATE-QUEUE_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "queue-config/corporate-queue-config",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-queue-config",
                        //name: "Retail-Queue",
                        name: "RETAIL-QUEUE_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "queue-config/retail-queue-config",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "onboarding-customer",
                //name: "Onboarding-Customer",
                name: "ONBOARDING-CUSTOMER_VIEW",
                title: "Onboarding Customer",
                translate: "NAVIGATION_LABEL_ONBOARD_CUSTOMER",
                type: "collapsable",
                icon: "info",
                customIcon: {
                    black: "assets/icons/navigation/black/Onboard Customer_32 X 32.png",
                    white: "assets/icons/navigation/white/Onboard Customer_32 X 32.png",
                },
                role: "maker",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-onboarding",
                        //name: "Corporate-Onboarding-Customer",
                        name: "CORPORATE-ONBOARDING-CUSTOMER_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "/onboarding-customer/corporate-doc-checklist",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-onboarding",
                        //name: "Retail-Onboarding-Customer",
                        name: "RETAIL-ONBOARDING-CUSTOMER_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "/onboarding-customer/retail-doc-checklist",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "trigger-event",
                //name: "Trigger-Event",
                name: "TRIGGER-EVENT_VIEW",
                title: "Trigger Event",
                translate: "NAVIGATION_LABEL_TRIGGER_EVENT",
                type: "collapsable",
                icon: "group_add",
                customIcon: {
                    black: "assets/icons/navigation/black/Initiate Trigger Event_32 X 32.png",
                    white: "assets/icons/navigation/white/Initiate Trigger Event_32 X 32.png",
                },
                role: "maker",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-trigger-event",
                        //name: "Corporate-Trigger-Event",
                        name: "CORPORATE-TRIGGER-EVENT_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "trigger-event/corporate-trigger-event",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-trigger-event",
                        //name: "Retail-Trigger-Event",
                        name: "RETAIL-TRIGGER-EVENT_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "trigger-event/retail-trigger-event",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "idv-doc-checklist",
                //name: "Document-Checklist",
                name: "DOCUMENT-CHECKLIST_VIEW",
                title: "Document Checklist",
                translate: "NAVIGATION_LABEL_IDV_DOCUMENT_CHECKLIST",
                type: "collapsable",
                icon: "check_circle",
                customIcon: {
                    black: "assets/icons/navigation/black/Document Checklist_32 X 32.png",
                    white: "assets/icons/navigation/white/Document Checklist_32 X 32.png",
                },
                role: "maker",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-idv-checklist",
                        //name: "Corporate-Document-Checklist",
                        name: "CORPORATE-DOCUMENT-CHECKLIST_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "idv-doc-checklist/corporate-doc-checklist",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-idv-checklist",
                        //name: "Retail-Document-Checklist",
                        name: "RETAIL-DOCUMENT-CHECKLIST_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "idv-doc-checklist/retail-doc-checklist",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "Unwrapping",
                //name: "Unwrapping",
                name: "UNWRAPPING_VIEW",
                title: "Unwrapping",
                translate: "NAVIGATION_LABEL_UNWRAPPING",
                type: "item",
                icon: "group_add",
                url: "/unwrapping",
                customIcon: {
                    black: "assets/icons/navigation/black/Unwrapping_32 X 32.png",
                    white: "assets/icons/navigation/white/Unwrapping_32 X 32.png",
                },
                role: "maker",
                resourceType: "HomePage",
            },
            {
                id: "ongoing-name-screening",
                name: "ONGOING-NAME-SCREENING_VIEW",
                title: "Ongoing Name Screening",
                translate: "NAVIGATION_LABEL_ONGOING_NAME_SCREENING",
                type: "collapsable",
                icon: "group_add",
                customIcon: {
                    black: "assets/icons/navigation/black/Ongoing Name Screening_32 X 32.png",
                    white: "assets/icons/navigation/white/Ongoing Name Screening_32 X 32.png",
                },
                role: "maker",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-ongoing-screening",
                        //name: "Corporate-Document-Checklist",
                        name: "CORPORATE-ONGOING-NAME-SCREENING_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "ongoing-name-screening/corporate-ongoing-name-screening",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-ongoing-screening",
                        //name: "Retail-Document-Checklist",
                        name: "RETAIL-ONGOING-NAME-SCREENING_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "ongoing-name-screening/retail-ongoing-name-screening",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "due-diligence-configuration",
                //name: "Due-Diligence-Configuration",
                name: "DUE-DILIGENCE-CONFIGURATION_VIEW",
                title: "Due Diligence Configuration",
                translate: "NAVIGATION_LABEL_DUE_DILIGENCE",
                type: "collapsable",
                icon: "cloud_upload",
                customIcon: {
                    black: "assets/icons/navigation/black/Due Diligence Configuration_32 X 32.png",
                    white: "assets/icons/navigation/white/Due Diligence Configuration_32 X 32.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-due-diligence",
                        //name: "Corporate-Due-Diligence-Configuration",
                        name: "CORPORATE-DUE-DILIGENTCE-CONFIGURATION_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "due-diligence/corporate-due-diligence",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-due-diligence",
                        //name: "Retail-Due-Diligence-Configuration",
                        name: "RETAIL-DUE-DILIGENTCE-CONFIGURATION_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "due-diligence/retail-due-diligence",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "periodic-review-configuration",
                //name: "Periodic-Review-Configuration",
                name: "PERIODIC-REVIEW-CONFIGURATION_VIEW",
                title: "Periodic Review Configuration",
                translate: "NAVIGATION_LABEL_PERIODIC_REVIEW",
                type: "collapsable",
                icon: "cloud_upload",
                customIcon: {
                    black: "assets/icons/navigation/black/Periodic Review_30 X 30.png",
                    white: "assets/icons/navigation/white/Periodic Review_30 X 30.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-periodic-review",
                        //name: "Corporate-Periodic-Review-Configuration",
                        name: "CORPORATE-PERIODIC-REVIEW-CONFIGURATION_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "periodic-review/corporate-periodic-review",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-periodic-review",
                        //name: "Retail-Periodic-Review-Configuration",
                        name: "RETAIL-PERIODIC-REVIEW-CONFIGURATION_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "periodic-review/retail-periodic-review",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "notification-configuration",
                name: "NOTIFICATION-CONFIGURATION_VIEW",
                title: "Notification Configuration",
                translate: "NAVIGATION_LABEL_NOTIFICATION",
                type: "collapsable",
                icon: "cloud_upload",
                customIcon: {
                    black: "assets/icons/navigation/black/Notification Engine_32 X 32.png",
                    white: "assets/icons/navigation/white/Notification EngineW_32 X 32.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-notification-config",
                        name: "CORPORATE-NOTIFICATION_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "notification-configuration/corporate-notification-config",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-notification-config",
                        name: "RETAI-NOTIFICATION_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "notification-configuration/retail-notification-config",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "risk-rating-configuration",
                //name: "Risk-Rating-Configuration",
                name: "RISK-RATING-CONFIGURATION_VIEW",
                title: "Risk Rating Configuration",
                translate: "NAVIGATION_LABEL_RISK_RATING",
                type: "collapsable",
                icon: "group_add",
                role: "tadmin",
                resourceType: "HomePage",
                customIcon: {
                    black: "assets/icons/navigation/black/Risk Rating Configuration_32 X 32.png",
                    white: "assets/icons/navigation/white/Risk Rating Configuration_32 X 32.png",
                },
                children: [
                    {
                        id: "corporate-risk-rating",
                        //name: "Corporate-Risk-Rating-Configuration",
                        name: "CORPORATE-RISK-RATING-CONFIGURATION_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "risk-rating-configuration/corporate-risk-rating",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-risk-rating",
                        //name: "Retail-Risk-Rating-Configuration",
                        name: "RETAIL-RISK-RATING-CONFIGURATION_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "risk-rating-configuration/retail-risk-rating",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "risk-engine",
                //name: "Risk-Engine",
                name: "RISK-ENGINE_VIEW",
                title: "Risk Engine",
                translate: "NAVIGATION_LABEL_RISK_ENGINE",
                type: "collapsable",
                icon: "info",
                customIcon: {
                    black: "assets/icons/navigation/black/Risk Engine Configuration_32 X 32.png",
                    white: "assets/icons/navigation/white/Risk Engine Configuration_32 X 32.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-risk-engine",
                        //name: "Corporate-Risk-Engine",
                        name: "CORPORATE-RISK-ENGINE_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "risk-engine/corporate-risk-engine",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-risk-engine",
                        //name: "Retail-Risk-Engine",
                        name: "RETAIL-RISK-ENGINE_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "risk-engine/retail-risk-engine",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "risk-engine-cal",
                //name: "Risk-Engine",
                name: "RISK-ENGINE-CALCULATION-CONFIG_VIEW",
                title: "Risk Engine Calucultion Config",
                translate: "NAVIGATION_LABEL_RISK_ENGINE_CAL_CONFIG",
                type: "collapsable",
                icon: "group_add",
                customIcon: {
                    black: "assets/icons/navigation/black/Risk Engine Configuration_32 X 32.png",
                    white: "assets/icons/navigation/white/Risk Engine Configuration_32 X 32.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-risk-engine-cal",
                        //name: "Corporate-Risk-Engine",
                        name: "CORPORATE-RISK-ENGINE-CALCULATION-CONFIG_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "risk-engine-cal/corporate-risk-engine-cal",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-risk-engine-cal",
                        //name: "Retail-Risk-Engine",
                        name: "RETAIL-RISK-ENGINE-CALCULATION-CONFIG_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "risk-engine-cal/retail-risk-engine-cal",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "workflow-config",
                //name: "Workflow-Configuration",
                name: "WORKFLOW-CONFIGURATION_VIEW",
                title: "Workflow Config",
                translate: "NAVIGATION_LABEL_WORKFLOW",
                type: "collapsable",
                icon: "group_add",
                customIcon: {
                    black: "assets/icons/navigation/black/Workflow Configuration_32 X 32.png",
                    white: "assets/icons/navigation/white/Workflow Configuration_32 X 32.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-workflow-config",
                        //name: "Corporate-Workflow-Configuration",
                        name: "CORPORATE-WORKFLOW-CONFIGURATION_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "workflow-config/corporate-workflow-config",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-workflow-config",
                        //name: "Retail-Workflow-Configuration",
                        name: "RETAIL-WORKFLOW-CONFIGURATION_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "workflow-config/retail-workflow-config",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                ],
            },

            {
                id: "adminUnwrapping",
                //name: "Unwrapping-admin",
                name: "UNWRAPPING-ADMIN_VIEW",
                title: "Unwrapping",
                translate: "NAVIGATION_LABEL_UNWRAPPING_CONFIG",
                type: "item",
                icon: "group_add",
                customIcon: {
                    black: "assets/icons/navigation/black/Unwrapping_32 X 32.png",
                    white: "assets/icons/navigation/white/Unwrapping_32 X 32.png",
                },
                url: "/admin-unwrapping",
                role: "tadmin",
                resourceType: "HomePage",
            },
            {
                id: "adminReports",
                //name: "Reports",
                name: "REPORTS_VIEW",
                title: "Reports",
                translate: "Reports",
                type: "collapsable",
                icon: "cloud_upload",
                customIcon: {
                    black: "assets/icons/navigation/black/IDV Documentation_32 X 32.png",
                    white: "assets/icons/navigation/white/IDV Documentation_32 X 32.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-admin-reports",
                        //name: "Corporate-Reports",
                        name: "CORPORATE-REPORTS_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "admin-reports/corporate-admin-reports",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-admin-reports",
                        //name: "Retail-Reports",
                        name: "RETAIL-REPORTS_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "admin-reports/retail-admin-reports",
                        role: "tadmin",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "dashboards",
                //name: "Queue",
                name: "DASHBOARDS_VIEW",
                title: "Dashboards",
                translate: "NAVIGATION_LABEL_DASHBOARDS",
                type: "collapsable",
                icon: "group_add",
                customIcon: {
                    black: "assets/icons/navigation/black/DASHBOARDS_32 X 32.png",
                    white: "assets/icons/navigation/white/DASHBOARDS_32 X 32.png",
                },
                role: "maker",
                resourceType: "HomePage",
                children: [
                    {
                        id: "corporate-dashboards",
                        //name: "Corporate-Queue",
                        name: "CORPORATE-DASHBOARDS_VIEW",
                        title: "Corporate",
                        segmentType: 1,
                        translate: "NAVIGATION_LABEL_CORPORATE",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "dashboards/corporate-dashboards",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                    {
                        id: "retail-dashboards",
                        //name: "Retail-Queue",
                        name: "RETAIL-DASHBOARDS_VIEW",
                        title: "Retail",
                        segmentType: 2,
                        translate: "NAVIGATION_LABEL_RETAIL",
                        type: "item",
                        icon: "keyboard_arrow_right",
                        url: "dashboards/retail-dashboards",
                        role: "maker",
                        resourceType: "HomePage",
                    },
                ],
            },
            {
                id: "License-Agreement",
                //name: "Licence Aggrement",
                name: "LICENSE-AGREEMENT_VIEW",
                title: "License Agreement",
                translate: "NAVIGATION_LABEL_LICENSE_AGREEMENT",
                type: "item",
                icon: "perm_media",
                url: "/license-agreement",
                customIcon: {
                    black: "assets/icons/navigation/black/collaboration.png",
                    white: "assets/icons/navigation/white/collaboration.png",
                },
                role: "tadmin",
                resourceType: "HomePage",
            },
        ],
    },
];

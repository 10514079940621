import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MetaFieldsConfig } from "../meta-fields.config";
import * as moment from "moment";

@Injectable()
export class MetaFieldsControlService {
    newControl(fieldTypeId, input = "", isMandatory, isDisabled) {
        const disabled = isDisabled;
        const value =
            fieldTypeId === 106 ? (input ? moment(input) : input) : input;
        return isMandatory
            ? new FormControl({ value, disabled }, Validators.required)
            : new FormControl({ value, disabled });
    }
    toFormGroup(fields: MetaFieldsConfig<string>[]): FormGroup {
        const group: any = {};
        fields.forEach((field) => {
            const fieldTypeId = field.fieldTypeId;
            const input = field.value;
            const isMandatory = field.isMandatory;
            const isDisabled = field.isDisabled;
            group[field.key] = this.newControl(
                fieldTypeId,
                input,
                isMandatory,
                isDisabled
            );
        });
        return new FormGroup(group);
    }
    toCheckboxFormGroup(fields: any[]): FormGroup {
        const group: any = {};
        fields.forEach((field) => {
            group[field.key] = new FormControl(!!field.checked);
        });
        return new FormGroup(group);
    }
}

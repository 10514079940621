import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceX',
  pure: false
})
export class ReplaceXPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let precision = (args >= 100)? 4 : (args < 10 ) ? 2 : 3;
    args = args.toPrecision(precision);
    let val = value.replace(/X%/g, `${args}%`);
    return val;
  }

}

import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
    HttpClientModule,
    HttpClient,
    HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
    RouterModule,
    Routes,
    ActivatedRoute,
    PreloadAllModules,
} from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";

import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HomeComponent } from "./main/home/home.component";
import { MainInterceptor } from "./services/interceptors/main.interceptor";
import { SampleModule } from "./main/sample/sample.module";
import { SSOService } from "./oauth2/sso/sso.service";
import { OAuthModule } from "angular-oauth2-oidc";
import { StartupService } from "./setup.service";
import {
    AuthGuard,
    AdminGuard,
    MakerGuard,
    DiligentGuard,
} from "./services/guards";
import { CheckerGuard } from "./services/guards/checker.guard";
import { CheckeradminGuard } from "./services/guards/checkeradmin.guard";

import { NgrxHelperModule } from "ngrx-helpers";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        "/assets/tenants/default/navigation/labels/"
    );
}
const appRoutes: Routes = [
    {
        path: "",
        redirectTo: "home",
        pathMatch: "full",
        // canActivate: [AuthGuard],
    },
    {
        path: "callback",
        redirectTo: "home",
        pathMatch: "full",
        // canActivate: [AuthGuard],
    },
    {
        path: "callback#access_token",
        redirectTo: "home",
        pathMatch: "full",
        // canActivate: [AuthGuard],
    },
    {
        path: "health-check",
        component: HealthCheckComponent,
    },
    {
        path: "home",
        component: HomeComponent,
        // canActivate: [AuthGuard],
    },
    // {
    //     path: "health-check",
    //     loadChildren: () =>
    //         import("app/main/health-check/health-check.module").then(
    //             (m) => m.HealthCheckModule
    //         ),
    //     // data: { name: "health-check" },
    //     // canActivate: [AuthGuard],
    // },
    {
        path: "onboard-tenant",
        loadChildren: () =>
            import("app/main/onboard-tenant/onboard-tenant.module").then(
                (m) => m.OnboardTenantModule
            ),
        data: { name: "Tenant-onboard" },
        // canActivate: [AuthGuard],
    },
    {
        path: "access-management",
        loadChildren: () =>
            import("app/main/access-management/access-management.module").then(
                (m) => m.AccessManagementModule1
            ),
        data: { name: "Access-management" },
        canActivate: [AuthGuard],
    },
    {
        path: "risk-engine-cal",
        loadChildren: () =>
            import("app/main/risk-engine-cal/risk-engine-cal.module").then(
                (m) => m.RiskEngineCalModule
            ),
        data: { name: "Risk-engine-cal" },
        canActivate: [AuthGuard],
    },
    {
        path: "application-preferences",
        loadChildren: () =>
            import(
                "app/main/application-preferences/application-preferences.module"
            ).then((m) => m.ApplicationPreferencesModule1),
        data: { name: "application-preferences" },
        canActivate: [AuthGuard],
    },
    {
        path: "role-management",
        loadChildren: () =>
            import("app/main/role-management/role-management.module").then(
                (m) => m.RoleManagementModule1
            ),
        data: { name: "Role-management" },
        canActivate: [AuthGuard],
    },
    {
        path: "user-management",
        loadChildren: () =>
            import("app/main/user-management/user-management.module").then(
                (m) => m.UserManagementModule1
            ),
        data: { name: "User-management" },
        canActivate: [AuthGuard],
    },
    {
        path: "resource-managment",
        loadChildren: () =>
            import(
                "app/main/resource-managment/resource-managment.module"
            ).then((m) => m.ResourceManagmentModule1),
        data: { name: "Resource-management" },
        canActivate: [AuthGuard],
    },
    {
        path: "booking-location",
        loadChildren: () =>
            import("app/main/booking-location/booking-location.module").then(
                (m) => m.BookingLocationModule
            ),
        data: { name: "Country-configuration" },
        canActivate: [AuthGuard],
    },
    {
        path: "manage-master-data",
        loadChildren: () =>
            import(
                "app/main/manage-master-data/manage-master-data.module"
            ).then((m) => m.ManageMasterDataDefaultModule),
        data: { name: "ManageMasterData" },
        canActivate: [AuthGuard],
    },
    {
        path: "idv-information",
        loadChildren: () =>
            import("app/main/idv-config/idv-config.module").then(
                (m) => m.IdvConfigModule
            ),
        data: { name: "IDV-CONFIGURATION" },
        canActivate: [AuthGuard],
    },
    {
        path: "manage-master-categories",
        loadChildren: () =>
            import(
                "app/main/manage-master-categories/manage-master-categories.module"
            ).then((m) => m.ManageMasterCategoriesDefaultModule),
        data: { name: "ManageMasterCategories" },
        canActivate: [AuthGuard],
    },
    {
        path: "coming-soon",
        loadChildren: () =>
            import("app/main/coming-soon/coming-soon.module").then(
                (m) => m.ComingSoonModule
            ),
        //canActivate: [AuthGuard],
    },
    {
        path: "dashboards",
        loadChildren: () =>
            import("app/main/dashboards/dashboards.module").then(
                (m) => m.Dashboards
            ),
        data: { name: "DashBoards" },
        //canActivate: [AuthGuard],
    },
    {
        path: "idv-doc-config",
        loadChildren: () =>
            import("app/main/idv-doc-config/idv-doc-config.module").then(
                (m) => m.IdvDocConfigDefaultModule
            ),
        data: { name: "IDV-DOCUMENTATION" },
        canActivate: [AuthGuard],
    },
    {
        path: "idv-doc-checklist",
        loadChildren: () =>
            import("app/main/idv-doc-checklist/idv-doc-config.module").then(
                (m) => m.IdvDocCheckListDefaultModule
            ),
        data: { name: "Document-Checklist" },
        canActivate: [AuthGuard],
    },
    {
        path: "onboarding-customer",
        loadChildren: () =>
            import("app/main/idv-doc-checklist/idv-doc-config.module").then(
                (m) => m.IdvDocCheckListDefaultModule
            ),
        data: { name: "Onboarding-Customer" },
        canActivate: [AuthGuard],
    },
    {
        path: "admin-unwrapping",
        loadChildren: () =>
            import("app/main/admin-unwrapping/admin-unwrapping.module").then(
                (m) => m.AdminUnwrappingDefaultModule
            ),
        data: { name: "Unwrapping-admin" },
        canActivate: [AuthGuard],
    },
    {
        path: "admin-reports",
        loadChildren: () =>
            import("app/main/admin-reports/admin-reports.module").then(
                (m) => m.AdminReportsDefaultModule
            ),
        data: { name: "Reports" },
        canActivate: [AuthGuard],
    },
    {
        path: "notification-configuration",
        loadChildren: () =>
            import(
                "app/main/notification-config/notification-config.module"
            ).then((m) => m.NotificationConfigModule),
        data: { name: "Notification Configuration" },
    },
    {
        path: "license-agreement",
        loadChildren: () =>
            import(
                "app/main/licence/licence.module"
            ).then((m) => m.LicenceModule),
        data: { name: "License Agreement" },
    },
    {
        path: "unwrapping",
        loadChildren: () =>
            import("app/main/maker-unwrapping/maker-unwrapping.module").then(
                (m) => m.MakerUnwrappingDefaultModule
            ),
        data: { name: "Unwrapping" },
        canActivate: [AuthGuard],
    },
    {
        path: "search-customer",
        loadChildren: () =>
            import("app/main/search-customer/search-customer.module").then(
                (m) => m.SearchCustomerDeafaultModule
            ),
        data: { name: "Search-Customer" },
        canActivate: [AuthGuard],
    },
    {
        path: "upload-special-dd",
        loadChildren: () =>
            import("app/main/upload-special-dd/upload-special-dd.module").then(
                (m) => m.UploadSpecialDdModuleDefault
            ),
        data: { name: "Due-Diligence-Configuration" },
        canActivate: [AuthGuard],
    },
    {
        path: "risk-rating-configuration",
        loadChildren: () =>
            import(
                "app/main/risk-rating-configuration/risk-rating-configuration.module"
            ).then((m) => m.RiskRatingConfigurationModule),
        data: { name: "Risk-Rating-Configuration" },
        canActivate: [AuthGuard],
    },
    {
        path: "risk-engine",
        loadChildren: () =>
            import("app/main/risk-engine/risk-engine.module").then(
                (m) => m.RiskEngineModule
            ),
        data: { name: "Risk-Engine" },
        canActivate: [AuthGuard],
    },
    {
        path: "workflow-config",
        loadChildren: () =>
            import("app/main/workflow-config/workflow-config.module").then(
                (m) => m.WorkflowConfigModuleDefault
            ),
        data: { name: "Workflow-Configuration" },
        canActivate: [AuthGuard],
    },
    {
        path: "onboarding",
        loadChildren: () =>
            import("app/main/onboarding/onboarding.module").then(
                (m) => m.OnboardingModule
            ),
        data: { name: "Onboarding-Customer" },
        // canActivate: [AuthGuard],
    },
    {
        path: "due-diligence",
        loadChildren: () =>
            import("app/main/due-diligence/due-diligence.module").then(
                (m) => m.DueDiligenceModule
            ),
        data: { name: "Due-Diligence-Configuration" },
        canActivate: [AuthGuard],
    },
    {
        path: "periodic-review",
        loadChildren: () =>
            import("app/main/periodic-review/periodic-review.module").then(
                (m) => m.PeriodicReviewModule
            ),
        data: { name: "Periodic-Review-Configuration" },
        canActivate: [AuthGuard],
    },
    {
        path: "demos",
        component: DemosComponent,
        // canActivate: [AuthGuard, AdminGuard]
    },
    {
        path: "pool-config",
        loadChildren: () =>
            import("app/main/pool-config/pool-config.module").then(
                (m) => m.PoolConfigModuleDefault
            ),
        data: { name: "pool" },
        canActivate: [AuthGuard],
    },
    {
        path: "queue-config",
        loadChildren: () =>
            import("app/main/queue-config/queue-config.module").then(
                (m) => m.QueueConfigModuleDefault
            ),
        data: { name: "queue" },
        canActivate: [AuthGuard],
    },
    {
        path: "trigger-event",
        loadChildren: () =>
            import("app/main/trigger-event/trigger-event.module").then(
                (m) => m.TriggerEventModuleDefault
            ),
        data: { name: "Trigger-Event" },
        canActivate: [AuthGuard],
    },
    {
        path: "ongoing-name-screening",
        loadChildren: () =>
            import(
                "app/main/ongoing-name-screening/ongoing-name-screening.module"
            ).then((m) => m.OngoingNameScreening),
        data: { name: "ongoing-name-screening" },
    },
    {
        path: "**",
        component: ErrorComponent,
    },
];

import { SharedModule } from "dil-shared";
import { ErrorComponent } from "./main/error/error.component";
import { TermsConditionsComponent } from "../../projects/shared/src/public_api";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import * as fromRoot from "./state";
import * as fromAppReducers from "./store/app.reducers";
import * as fromAppEffects from "./store/app.effects";
import { SharedStoreModule } from "shared-store";
import { ApiInterceptor } from "./services/interceptors/api.interceptor";
import { DemosComponent } from "./main/demos/demos.component";
import { MetaComponentsDemoModule } from "projects/meta-components/src/public-api";
import { MatProgressBarModule } from "@angular/material/progress-bar";

import { OngoingNameScreeningComponent } from "projects/ongoing-name-screening/src/projects";
import { DedupePopupComponent } from "projects/meta-components/src/lib/components/dedupe-popup/dedupe-popup/dedupe-popup.component";
import { GetAssociationsPopupComponent } from "projects/meta-components/src/lib/components/get-associations-popup/get-associations-popup/get-associations-popup.component";
import { HealthCheckComponent } from "./main/health-check/health-check.component";

export function startupServiceFactory(
    startupService: StartupService
): Function {
    return () => startupService.load();
}
@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ErrorComponent,
        DemosComponent,
        DedupePopupComponent,
        GetAssociationsPopupComponent,
        HealthCheckComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        MetaComponentsDemoModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            isolate: true,
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatCardModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        SharedModule,
        FuseThemeOptionsModule,
        OAuthModule.forRoot(),
        // App modules
        LayoutModule,
        StoreModule.forRoot(
            { reducers: fromAppReducers.reducers },
            { metaReducers: fromRoot.metaReducers }
        ),
        EffectsModule.forRoot([fromAppEffects.AppEffects]),
        StoreModule.forFeature(fromAppReducers.key, fromAppReducers.reducers),
        StoreDevtoolsModule.instrument({
            name: "Diligent Ngrx Store DevTools",
            logOnly: environment.production,
        }),
        NgrxHelperModule.forRoot(),
        SharedStoreModule,
        MatProgressBarModule,
    ],
    providers: [
        StartupService,
        {
            // Provider for APP_INITIALIZER
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        SSOService,
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        TermsConditionsComponent,
        DedupePopupComponent,
        GetAssociationsPopupComponent,
    ],
    exports: [MatProgressBarModule],
})
export class AppModule {
    constructor(private ssoService: SSOService) {
        // this.ssoService.getAccessToken();
    }
}

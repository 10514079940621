import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { selectCurrentTab, selectPreviousTab } from "shared-store";
import { Observable, of, Subscription } from "rxjs";
import { CLEAR_TAB_CHANGE, START_TAB_CHANGE } from "shared-store";
import { Router } from "@angular/router";

@Component({
    selector: "lib-meta-tabs",
    templateUrl: "./meta-tabs.component.html",
    styleUrls: ["./meta-tabs.component.scss"],
})
export class MetaTabsComponent implements OnInit, OnDestroy {
    @Input() selectedTab = null;
    @Input() tabs = null;
    @Input() localeId = null;
    @Input() tabStatus = null;
    @Output() tabChangedUpdate = new EventEmitter<{ data }>();
    @Output() ledgerOutput = new EventEmitter<any>();
    @Output() levelOutput = new EventEmitter<{ data }>();
    @Input() level = 0;
    @Input() exportFlag = null;
    @Input() exportGetDataForPost;
    @Input() localeDataName;
    @Input() SendEmailLink = null;
    @Input() flagForCancel = null;
    tabsCount = 0;
    selected = 0;
    subscription: Subscription;
    subscriptionForPrevious: Subscription;

    currentTab$: Observable<any> = this.store.select(selectCurrentTab);
    previousTab$: Observable<any> = this.store.select(selectPreviousTab);

    @Input() ledger = null;
    previousSelectedTab: number = 0;
    selectedTabValue: number = 0;

    constructor(private store: Store<any>, private router: Router) {}
    ngOnInit(): void {
        console.log(this.tabs);
        this.subscribeCurrentTab();
        this.subscribePreviousTab();
    }

    ngOnChanges() {
        if (this.tabs) this.tabsCount = this.tabs.length;
        if (this.ledger) {
            this.selected = this.ledger["level" + this.level];
        }
    }

    subscribeCurrentTab() {
        this.subscription = this.currentTab$.subscribe((currentTab) => {
            currentTab !== null && this.handleTabChange(currentTab);
        });
    }
    subscribePreviousTab() {
        this.subscriptionForPrevious = this.previousTab$.subscribe(
            (previousTab) => {
                previousTab !== null &&
                    this.handlePreviousTabChange(previousTab);
            }
        );
    }
    //Your tab change logic goes here
    handleTabChange(currentTab) {
        const container = document.querySelector("#container-3");
        container.scrollTop = 0;
        const { tabName, tabStatus, tabLevel, tabLevelNextTab } = currentTab;

        if (tabStatus === "SAVE_AND_PROCEED") return false;
        this.ledger["level" + this.level] = this.selected;

        if (tabLevel === this.level) {
            if (this.selected === this.tabsCount - 1) {
                //If save and submit on last tab for same level
                if (tabLevel === 0) {
                    //If we are on top level
                    //TODO:go to next tab using tabName provided

                    this.router.navigate([tabLevelNextTab]);
                    this.store.dispatch(CLEAR_TAB_CHANGE());
                } else {
                    this.ledger["level" + this.level] = tabLevel - 1;
                    //Switch to next partent tab
                    this.store.dispatch(
                        START_TAB_CHANGE({
                            currentTab: {
                                ...currentTab,
                                tabLevel: tabLevel - 1,
                            },
                        })
                    );
                }
            } else {
                this.selected++;
                this.ledger["level" + this.level] = this.selected;
                this.store.dispatch(CLEAR_TAB_CHANGE());
            }
            this.ledgerOutput.emit(this.ledger);
        }
    }
    tabChangedUpdateData() {
        this.tabChanged("");
    }
    tabChanged($event) {
        if ($event) {
            this.previousSelectedTab = this.selectedTabValue;
            this.selectedTabValue = $event.index;
            this.selected = $event.index;

            this.ledger = Object.assign({}, this.ledger, {
                ["level" + this.level]: $event.index,
            });
            //   this.ledger["level"+this.level] = this.selected;
            //let i = this.level++;
            for (let i = this.level + 1; i > this.level; i++) {
                if (this.ledger["level" + i]) {
                    this.ledger["level" + i] = 0;
                } else break;
            }
        }

        this.tabChangedUpdate.emit({ data: true });
        // this.ledgerOutput.emit(this.ledger);

        this.ledgerChanged(this.ledger);
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.subscriptionForPrevious.unsubscribe();
    }

    ledgerChanged($event) {
        if ($event) {
            this.ledgerOutput.emit($event);
        }
    }

    handlePreviousTabChange(previousTab) {
        const { tabName, tabStatus, tabLevel, previousLedger } = previousTab;
        const levelTab = tabLevel["level" + this.level];
        const ledgerTab = this.ledger["level" + this.level];

        if (tabStatus == "PREVIOUS_TAB_CHANGE") {
            if (tabLevel === this.ledger) {
                if (tabLevel === this.ledger) {
                    if (this.level === this.tabsCount - 1) {
                        if (levelTab === 0) {
                        } else {
                            this.ledger = previousLedger;
                            this.selected = this.previousSelectedTab;
                            //   //  this.ledger= Object.assign(
                            //         {},
                            //         this.ledger,
                            //         { ["level"+this.level]:this.previousSelectedTab }
                            //     );
                            // this.previousSelectedLedger= this.ledger ;
                        }
                    } else {
                        this.ledger = previousLedger;
                        this.selected = this.previousSelectedTab;
                        //    this.ledger= Object.assign(
                        //        {},
                        //        this.ledger,
                        //        { ["level"+this.level]:this.previousSelectedTab }
                        //    );
                    }
                }
            }
        }
    }
}

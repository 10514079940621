import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
    selector: "lib-meta-accordion-demo",
    templateUrl: "./meta-accordion-demo.component.html",
    styleUrls: ["./meta-accordion-demo.component.scss"],
})
export class MetaAccordionDemoComponent implements OnInit {
    constructor() {}
    form: FormGroup;
    payLoad = "";
    title = " Basic Title";
    pep = "yes";
    ngOnInit(): void {
        this.form = new FormGroup({
            dd: new FormGroup({
                pep: new FormControl(),
            }),
            doc: new FormGroup({
                docStatus: new FormControl(),
            }),
        });
    }
    onSubmit(): void {
        this.payLoad = JSON.stringify(this.form.getRawValue());
    }
}

import { NgModule } from "@angular/core";
import { MetaFormDemoComponent } from "./meta-form-wrapper-demo/meta-form-demo/meta-form-demo.component";
import { MetaFormWrapperDemoComponent } from "./meta-form-wrapper-demo/meta-form-wrapper-demo.component";
import { MetaComponentsModule } from "../components/meta-components.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { MetaAccordionDemoComponent } from "./meta-accordion-demo/meta-accordion-demo.component";
import { MaterialModule } from "../components/material.module";
import { MetaTableDemoComponent } from "./meta-table-demo/meta-table-demo.component";
import { MetaFieldsDemoComponent } from "./meta-fields-demo/meta-fields-demo.component";
import { MetaTabsDemoComponent } from "./meta-tabs-demo/meta-tabs-demo.component";
import { Comp1Component } from "./meta-tabs-demo/components/comp1.component";
import { Comp2Component } from "./meta-tabs-demo/components/comp2.component";
import { TabService } from "./meta-tabs-demo/tab.service";
import { MetaUploadDemoComponent } from "./meta-upload-demo/meta-upload-demo.component";
import { MetaTabsV2DemoComponent } from './meta-tabs-v2-demo/meta-tabs-v2-demo.component';
const components = [
    MetaFormDemoComponent,
    MetaFormWrapperDemoComponent,
    MetaAccordionDemoComponent,
    MetaTableDemoComponent,
    MetaFieldsDemoComponent,
    MetaTabsDemoComponent,
    MetaUploadDemoComponent,
    MetaTabsV2DemoComponent
];
@NgModule({
    declarations: [...components, Comp1Component, Comp2Component, MetaTabsV2DemoComponent],
    imports: [
        MetaComponentsModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
    ],
    exports: [MetaComponentsModule, ...components, MaterialModule],
    entryComponents: [Comp1Component, Comp2Component],
    providers: [TabService],
})
export class MetaComponentsDemoModule {}

// case-management.module.ts
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseManagementComponent } from './case-management/case-management.component';
import { CustomerAdditionalInfoComponent } from './customer-additional-info/customer-additional-info.component';
import { ResolveSectionComponent } from './resolve-section/resolve-section.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CaseManagementService } from './case-management.service';
import { TriggerCommentComponent } from './trigger-comment/trigger-comment.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { NgxSpinnerModule } from 'ngx-spinner';



@NgModule({
  declarations: [
        CaseManagementComponent,
        CustomerAdditionalInfoComponent,
      ResolveSectionComponent,
      TriggerCommentComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
      CommonModule,
    FormsModule,
    MatDialogModule,
    MatSortModule,
      NgxSpinnerModule,
      ReactiveFormsModule,
      TranslateModule,
      MatTableModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatOptionModule,
    MatTabsModule,
       MatCardModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressBarModule
    ],
  entryComponents: [CaseManagementComponent,TriggerCommentComponent],
  providers:[CaseManagementService]
})
export class CaseManagementModule { }

import { Component, OnInit, Input, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { StorageService } from "dil-shared";
import { FormGroup } from "@angular/forms";
import { AccessRights } from "projects/onboarding/src/lib/corporate/constants/access-permission.constants";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { CorporateOnboardingState } from "projects/onboarding/src/lib/corporate/store/corporate-onboarding.reducers";
import {
    RootComponent,
    getAppStatus,
    getLocaleId,
    START_TAB_CHANGE,
} from "shared-store";
import { UtilService } from "projects/shared/src/public_api";

@Component({
    selector: "lib-get-associations-popup",
    templateUrl: "./get-associations-popup.component.html",
    styleUrls: ["./get-associations-popup.component.scss"],
})
export class GetAssociationsPopupComponent implements OnInit {
    page: string = "getassociations";
    getAssociationresponse: any = [];
    metaForm: any[];
    fieldForm: FormGroup = new FormGroup({});
    rolePermissions: AccessRights = {
        readOnly: true,
        review: false,
        edit: false,
    };
    @Input() localeId: number;
    relatedPartyMap = new Map<string, string>();
    dedupeCheckedFields: any = {};
    localeIdData$: Observable<number> = this._store.select(getLocaleId);
    constructor(
        private dialogRef: MatDialogRef<GetAssociationsPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public receivedData,
        private storageService: StorageService,
        private _store: Store<CorporateOnboardingState>,
        private utilService: UtilService
    ) {}

    ngOnInit(): void {
        this.utilService.loadTanslations(this.page);
        console.log(
            "receivedData ::: " + JSON.stringify(this.receivedData.res)
        );
        this.getAssociationresponse = [];
        this.getAssociationresponse = this.receivedData.res;
        this.fieldForm = this.receivedData.filedform;
        this.relatedPartyMap = this.receivedData.relatedPartyMap;
    }
    dedupeField($event) {
        if ($event.checked) {
            this.dedupeCheckedFields = Object.assign(
                {},
                this.dedupeCheckedFields,
                {
                    [$event.fieldInfo.fieldId]: $event.fieldInfo,
                }
            );
        } else {
            delete this.dedupeCheckedFields[$event.fieldInfo.fieldId];
        }
        this.getAssociationresponse.forEach((relatedParty) => {
            relatedParty.metaForm.forEach((metaField) => {
                if (metaField.items[0].id == $event.fieldInfo.id) {
                    metaField.deduped = $event.checked;
                } else if (metaField.id == $event.fieldInfo.fieldId) {
                    metaField.isCopiedSomewhere = $event.checked;
                }
            });
        });
    }
    copyFieldValues() {
        console.log(
            "this.dedupeCheckedFields :::" +
                JSON.stringify(this.dedupeCheckedFields)
        );
        this.dialogRef.close(this.dedupeCheckedFields);
    }
}

export class MetaCheckboxConfig {
    id: number;
    label: string;
    checked: boolean;
    labelPosition: string;
    disabled: boolean;
    template: string;
    localeCode : string;
    localeData : [];
    data: [];
    constructor(options: {
        id?: number;
        label?: string;
        checked?: boolean;
        labelPosition?: string;
        disabled?: boolean;
        template?: string;
        localeCode ?: string;
        localeData ?: [];
        data? : [];
    }) {
        this.id = options.id || null;
        this.label = options.label;
        this.checked = !!options.checked;
        this.labelPosition = options.labelPosition || "after";
        this.disabled = !!options.disabled;
        this.template = options.template || null;
        this.localeCode = options.localeCode || null;
        this.localeData = options.localeData || [];
        this.data = options.data || [];
    }
}
export class MetaCheckbox extends MetaCheckboxConfig {
    type = "checkbox";
    constructor(options: {} = {}) {
        super(options);
    }
}

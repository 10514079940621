import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MetaTabsV2Component } from "./meta-tabs-v2/meta-tabs-v2.component";
import { MaterialModule } from "../material.module";
import { MetaTabsV2WrapperComponent } from "./meta-tabs-v2-wrapper/meta-tabs-v2-wrapper.component";

const components = [MetaTabsV2Component, MetaTabsV2WrapperComponent];

@NgModule({
    declarations: [...components],
    imports: [CommonModule, MaterialModule],
    exports: [...components],
})
export class MetaTabsV2Module {}

import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { StorageService } from "dil-shared";
import { UtilService } from "projects/shared/src/public_api";

@Component({
    selector: "lib-dedupe-popup",
    templateUrl: "./dedupe-popup.component.html",
    styleUrls: ["./dedupe-popup.component.scss"],
})
export class DedupePopupComponent implements OnInit {
    page: string = "dedupe";
    @ViewChild(MatPaginator) paginator: MatPaginator;
    pagedResults: any[];
    COIData: any = [];
    localeId: any;
    customersegementFlag: boolean;
    selectedFullName: string;
    selectedDedupeResponse: any;

    constructor(
        private dialogRef: MatDialogRef<DedupePopupComponent>,
        @Inject(MAT_DIALOG_DATA) public receivedData,
        private storageService: StorageService,
        private utilService: UtilService
    ) {}

    ngOnInit(): void {
        this.utilService.loadTanslations(this.page);
        console.log(
            "receivedData ::: " + JSON.stringify(this.receivedData.res)
        );
        this.customersegementFlag = this.receivedData.customersegementFlag;
        this.pagedResults = this.receivedData.res?.slice(0, 5);
        this.COIData = this.receivedData.COIData;
        this.localeId = this.storageService.lang || 1;
    }

    onPageChange(event: any) {
        const startIndex = event.pageIndex * event.pageSize;
        const endIndex = startIndex + event.pageSize;
        this.pagedResults =
            this.receivedData.res?.slice(startIndex, endIndex) || [];
    }
    getUpdateCOI(value: String): string {
        const coiValue = this.COIData.find((item) => item.corpId == value);
        const localeId = this.localeId;
        const localeIdString = String(localeId);

        // Parse coiValue if it's a JSON string
        const coiValueObj =
            typeof coiValue === "string" ? JSON.parse(coiValue) : coiValue;

        // Check if coiValueObj and coiValueObj.localeData are defined
        if (coiValueObj && coiValueObj.localeData) {
            // Find the item in localeData array with matching localeId
            const matchedLocale = coiValueObj.localeData.find(
                (data) => String(data.localeId) === localeIdString
            );

            if (matchedLocale) {
                // Access the name property
                const countryName = matchedLocale.name;
                return countryName;
            } else {
                console.log("No matching locale found in coiValue.");
            }
        } else {
            console.log("coiValueObj or coiValueObj.localeData is undefined.");
        }

        return "NA";
    }

    filterResults(selectedResult: any) {
        this.selectedDedupeResponse = selectedResult;
        console.log(" this.selectedFullName ::: " + this.selectedFullName);
        console.log(
            " selectedResult.customerFlag ::: " + selectedResult.customerFlag
        );
        console.log(
            " this.selectedDedupeResponse ::: " + JSON.stringify(selectedResult)
        );
    }

    submitFullName() {
        this.dialogRef.close(this.selectedDedupeResponse);
    }
}
